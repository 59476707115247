import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch } from "react-redux";
import { toggleNavbar } from "../features/userSlice";
import FeedbackModal from "./FeedbackModal";
const Header = ({ page }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const handleSearch = (event) => {
    event.preventDefault();
    if (query.trim() !== "") {
      navigate(`/search?query=${query}`);
      setQuery("");
      //   toggleMenu();
    }
  };

  const toggleMenu = () => {
    dispatch(toggleNavbar());
    // document.body.style.overflow = showNav ? "" : "hidden";
  };

  const loginPage = page === "login";
  const buttonClasses = "font-semiBold";
  return (
    <div className="fixed top-0 left-0 right-0 flex flex-row gap-2 justify-between items-center px-3 md:px-8 border-b-2 border-gray-100 z-10 bg-white h-14 shadow-sm">
      <div className="flex flex-row gap-2 lg:gap-20  items-center w-auto">
        <h4 className="hidden lg:block font-bold text-lg">
          Friengle{" "}
          <span className="text-[14px] text-primary-color absolute top-1 underline decoration-primary-color font-bold">
            beta
          </span>
        </h4>
        <GiHamburgerMenu
          className={`block my-auto lg:hidden fill-black size-7 cursor-pointer self-start`}
          onClick={toggleMenu}
        />
        <form className="relative" onSubmit={handleSearch}>
          <IoIosSearch
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
            size={20}
          />
          <input
            type="search"
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="w-full md:w-96 h-9 pl-10 pr-2 rounded-full focus:outline-none bg-gray-100 font-semiBold text-sm"
          />
        </form>
      </div>
      <div>
        <button
          className="font-semiBold underline text-[0.8rem] lg:text-[0.95rem] hover:text-primary-color hover:text-[0.85rem] hover:md:text-[1rem] duration-300"
          onClick={() => {
            setShowFeedbackModal(!showFeedbackModal);
          }}
        >
          Give Feedback
        </button>
      </div>
      <FeedbackModal show={showFeedbackModal} setShow={setShowFeedbackModal} />
    </div>
  );
};

export default Header;
