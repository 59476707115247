import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Friends.css";
import {
  getFriendsData,
  selectAllFriends,
  selectPendingRequests,
  selectReceivedRequests,
} from "../../features/friendsSlice";
import { useNavigate } from "react-router-dom";
import { IoPersonCircleSharp } from "react-icons/io5";

const Friends = ({ isCurrentUser, username }) => {
  const [activeTab, setActiveTab] = useState("all");
  const dispatch = useDispatch();
  const allFriends = useSelector(selectAllFriends);
  const pendingRequests = useSelector(selectPendingRequests);
  const receivedRequests = useSelector(selectReceivedRequests);
  const navigate = useNavigate();
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(getFriendsData({ tab: activeTab, username, navigate }));
  }, [activeTab, username, dispatch]);

  useEffect(() => {
    setActiveTab("all");
  }, [username]);

  let displayedUsers;
  if (activeTab === "all") {
    displayedUsers = allFriends;
  } else if (activeTab === "pending") {
    displayedUsers = pendingRequests;
  } else {
    displayedUsers = receivedRequests;
  }

  const usersList = displayedUsers.map((user, index) => {
    return (
      <button
        onClick={() => {
          navigate(`/profile/${user.username}`);
        }}
        className="flex justify-start items-center gap-2 text-xl tracking-wide "
      >
        <IoPersonCircleSharp className="size-11 fill-accent-color" />
        {user.username}
      </button>
    );
  });

  return (
    <div>
      {isCurrentUser && (
        <div className="mx-auto w-full max-w-3xl bg-gray-200 rounded-full flex justify-between p-1">
          {["all", "pending", "received"].map((btn) => {
            return (
              <button
                onClick={() => handleTabClick(btn)}
                className={`basis-2/6  transition-colors duration-300 py-1 rounded-full  ${
                  activeTab === btn ? "bg-white font-semiBold" : "font-regular"
                }`}
              >
                {btn}
              </button>
            );
          })}
        </div>
      )}

      <div className="flex-col gap-2 mt-2">{usersList}</div>
    </div>
  );
};

export default Friends;
