import React, { useEffect, useState } from "react";
import { request, formatDate } from "../util/util";
import { selectNotifications } from "../features/notificationsSlice";
import { useSelector } from "react-redux";
import { titleClasses } from "../util/globals";
import { AiOutlineMessage } from "react-icons/ai";
import { GoAlert } from "react-icons/go";
import { RiQuillPenLine } from "react-icons/ri";
import { IoPersonAddOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { GiTumbleweed } from "react-icons/gi";
import {
  MdOutlineEventBusy,
  MdPersonAddDisabled,
  MdReadMore,
} from "react-icons/md";
import { AiOutlineInbox } from "react-icons/ai";
import { FaRegSadCry } from "react-icons/fa";
import { BsEmojiTear } from "react-icons/bs";
import { selectUsername } from "../features/userSlice";
import { IoCheckmarkSharp } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import SEO from "../components/SEO";

const Dashboard = () => {
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const notifications = useSelector(selectNotifications);
  const username = useSelector(selectUsername);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { res, status } = await request("GET", "user/dashboard");
      if (status) {
        setInfo(res.data);
        // console.log("res");
        // console.log(res);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const mapUntilIndex = (array, index, callback) => {
    return array.slice(0, index).map(callback);
  };

  const cellClasses = "flex flex-col gap-4 mt-3";
  const cellTitleClasses = "text-md md:text-lg lg:text-xl font-semiBold ";
  const iconContainerClasses = "bg-gray-100 p-2 rounded-md";
  const iconClasses = "fill-black size-5";
  const noDataTextClasses = "text-sm text-black font-regular";
  const noDataIconClasses = "size-7 ";
  const callToActionClasses = "italic underline";
  const noDataContainer = "flex flex-row gap-2 items-center";
  const handleFriendRequest = async (user, type) => {
    const { res, status } = await request(
      "POST",
      `profile/${user}/${
        type === "accept" ? "accept-request" : "decline-request"
      }`
    );
    if (status) {
      setInfo({
        ...info,
        friend_requests_received: info.friend_requests_received.filter(
          (currUser) => currUser.username != user
        ),
      });
      toast.success(
        `${
          type === "accept"
            ? "Friend request accepted!"
            : "Friend request declined"
        }`
      );
    } else {
      toast.error("An error occurred");
    }
  };

  if (!loading)
    return (
      <div className="flex flex-col gap-10  lg:gap-8 ">
        <SEO
          title={`Dashboard - Friengle`}
          description={`View upcoming deadlines, latest messages, latest friend posts, and friend requests.`}
        />
        <h1 className={titleClasses}>Dashboard</h1>
        <div>
          <h2 className={cellTitleClasses}>Upcoming Deadlines</h2>
          <div className={cellClasses}>
            {info.upcoming_deadlines != null ? (
              info.upcoming_deadlines.map((goal) => {
                return (
                  <div className="flex flex-row items-center gap-2">
                    <div className={iconContainerClasses}>
                      <GoAlert className={iconClasses} />
                    </div>
                    <div className="p-1 flex flex-col">
                      <p className="text-xs font-light text-secondary-color">
                        {formatDate(goal.deadline)}
                      </p>
                      <p className="pl-4 text-md font-regular text-black">
                        {goal.title}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={noDataContainer}>
                <MdOutlineEventBusy className={noDataIconClasses} />
                <p className={noDataTextClasses}>
                  No upcoming deadlines,{" "}
                  <span className={callToActionClasses}>
                    consider adding some goals
                  </span>
                  .
                </p>
              </div>
            )}
          </div>
        </div>
        <div>
          <h2 className={cellTitleClasses}>Latest Messages</h2>
          <div className={cellClasses}>
            {info.accountability_messages != null ? (
              info.accountability_messages.map((message) => {
                return (
                  <div className="flex flex-row items-center gap-2">
                    <div className={iconContainerClasses}>
                      <AiOutlineMessage className={iconClasses} />
                    </div>
                    <div className="flex flex-col">
                      <p className="text-md font-regular text-black">
                        <span className="font-semiBold">
                          {message.username}
                        </span>
                        , Goal:
                        <span className="font-semiBold"> {message.title}</span>
                      </p>
                      <p className="pl-2">{message.message_text}</p>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={noDataContainer}>
                <AiOutlineInbox className={noDataIconClasses} />
                <p className={noDataTextClasses}>
                  No messages.{" "}
                  <span className={callToActionClasses}>
                    Once you add or are added as an accountability partner, the
                    latest messages will appear here
                  </span>
                  .
                </p>
              </div>
            )}
          </div>
        </div>
        <div>
          <h2 className={cellTitleClasses}>Keep up with friends</h2>
          <div className={cellClasses}>
            {info.latest_friends_posts != null ? (
              info.latest_friends_posts.map((post) => {
                return (
                  <div className="flex flex-row items-center gap-2">
                    <div className={iconContainerClasses}>
                      <RiQuillPenLine className={iconClasses} />
                    </div>
                    <div className="flex flex-col p-1">
                      <p className="text-md font-semiBold text-secondary-color">
                        {post.username}
                      </p>
                      <p className="pl-4 text-md font-regular text-black">
                        {post.title}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={noDataContainer}>
                <BsEmojiTear className={noDataIconClasses} />
                <p className={noDataTextClasses}>
                  No friend posts.
                  <span className={callToActionClasses}>
                    {" "}
                    When friends post something in the Social section, under
                    Friends, it will appear here
                  </span>
                  .
                </p>
              </div>
            )}
          </div>
        </div>
        {/* <div>
          <h2 className={cellTitleClasses}>Trending in community</h2>
          <div className={cellClasses}>
            {info.trending_community_posts.map((post) => {
              return (
                <div className="bg-gray-50 p-1">
                  <p className="text-md font-regular text-green-300">
                    {post.username}
                  </p>
                  <p className="pl-4 text-md font-regular text-black">
                    {post.title}
                  </p>
                </div>
              );
            })}
          </div>
        </div> */}
        {/* 
        <div>
          <h2 className={cellTitleClasses}>Latest Notifications</h2>
          <div className={cellClasses}>
            {mapUntilIndex(notifications, 4, (notification) => {
              return (
                <div>
                  <p className="text-sm font-light text-black">
                    {notification.message}
                  </p>
                </div>
              );
            })}
          </div>
        </div> */}
        <div>
          <h2 className={cellTitleClasses}>Friend Requests Received</h2>
          <div className={cellClasses}>
            {info.friend_requests_received != null ? (
              info.friend_requests_received.map((person) => {
                return (
                  <div className="flex flex-row w-5/6 md:w-[400px] justify-between">
                    <div className="flex flex-row gap-2 items-center">
                      <div className={iconContainerClasses}>
                        <IoPersonAddOutline className={iconClasses} />
                      </div>
                      <p className="text-md font-regular text-black">
                        {person.username}
                      </p>
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                      <button
                        onClick={() => {
                          handleFriendRequest(person.username, "accept");
                        }}
                        className={
                          "text-lg p-1 rounded-full border-2 border-green-500 hover:bg-green-500 hover:text-white  bg-white text-green-500  transition duration-300"
                        }
                      >
                        <IoCheckmarkSharp />
                      </button>
                      <button
                        onClick={() => {
                          handleFriendRequest(person.username, "decline");
                        }}
                        className={
                          "text-lg p-1 rounded-full border-2 border-red-500 hover:bg-red-500 hover:text-white bg-white text-red-500 transition duration-300"
                        }
                      >
                        <MdClose />
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={noDataContainer}>
                <MdPersonAddDisabled className={noDataIconClasses} />
                <p className={noDataTextClasses}>
                  No friend requests received.{" "}
                  <span className={callToActionClasses}>
                    Your friends can find you by searching for @{username}
                  </span>
                  .
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
};

export default Dashboard;
