import React, { useState } from "react";
import "./BasicGoal.css";
import { useNavigate } from "react-router-dom";
import NotesModal from "../NotesModal";
import { useDispatch } from "react-redux";
import { deleteGoal, changeGoalStatus } from "../../features/goalSlice";
import { AiFillDelete } from "react-icons/ai";
import { FaNoteSticky } from "react-icons/fa6";
import { FaShare } from "react-icons/fa";
import ShareModal from "../ShareModal";
import { FaCircleInfo } from "react-icons/fa6";
import SharedGoalParticipantsModal from "../SharedGoalParticipantsModal";
import { request } from "../../util/util";
// const BASE_URL = process.env.REACT_APP_BASE_URL;

const BasicGoal = ({ goal }) => {
  // const [addNote, setAddNote] = useState(false);
  const [viewShareModal, setViewShareModal] = useState(false);
  const [viewNotesModal, setViewNotesModal] = useState(false);
  const [viewParticipantsModal, setViewParticipantsModal] = useState(false);
  const [changingStatus, setChangingStatus] = useState(false);
  const [notes, setNotes] = useState([]);
  const [goalStatus, setGoalStatus] = useState(goal.status);
  const [accountabilityPartners, setAccountabilityPartners] = useState(
    goal.accountability_partners
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGoalDelete = async () => {
    await dispatch(deleteGoal({ goal, navigate }));
  };

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return dateObject.toLocaleDateString(undefined, options);
  };
  const handleChangeStatus = async (newGoalStatus) => {
    setChangingStatus(true);
    const { res, status } = await request(
      "PUT",
      "goal/status",

      {
        status: newGoalStatus,
        goalId: goal.id,
      }
    );
    if (status) {
      setGoalStatus((prev) => newGoalStatus);
      dispatch(changeGoalStatus({ id: goal.id, status: newGoalStatus }));
    }
    setChangingStatus(false);
  };
  return (
    <div className="border-2 border-gray-100 p-2 rounded-xl bg-[#00000002] shadow-md flex flex-col gap-5 w-full">
      <div className="flex flex-col">
        {goal.is_shared && (
          <button
            onClick={() => {
              setViewParticipantsModal(true);
            }}
            className="flex flex-row items-start gap-1 text-xs p-1 text-secondary-color"
          >
            <p className=" font-bold text-center">Shared</p>
            <FaCircleInfo className="" />
          </button>
        )}

        <select
          className="self-center text-xs font-light"
          value={goalStatus}
          onChange={(e) => handleChangeStatus(e.target.value)}
          disabled={changingStatus}
        >
          <option value="in progress">In Progress</option>
          <option value="pending">Pending</option>
          <option value="completed">Completed</option>
        </select>
      </div>
      <h3 className="text-md font-regular text-center w-5/6 self-center">
        {goal.title}
      </h3>
      {goal.deadline && (
        <p className="text-xs font-light text-center">
          <span className="text-red-300">Deadline:</span>
          {formatDate(goal.deadline)}
        </p>
      )}
      <div className="flex flex-row justify-between">
        <button
          onClick={() => {
            setViewNotesModal(true);
          }}
        >
          <FaNoteSticky className="fill-[#b58900]" />
        </button>
        <div>
          <button
            onClick={() => {
              setViewShareModal(true);
            }}
          >
            <FaShare className="fill-primary-color" />
          </button>
          <button
            onClick={() => {
              handleGoalDelete();
            }}
            style={{ marginLeft: 10 }}
          >
            <AiFillDelete className="fill-black hover:fill-red-500" />
          </button>
        </div>
      </div>
      <NotesModal
        viewNotesModal={viewNotesModal}
        setViewNotesModal={setViewNotesModal}
        notes={notes}
        setNotes={setNotes}
        goal_id={goal.id}
      />
      <ShareModal
        viewShareModal={viewShareModal}
        setViewShareModal={setViewShareModal}
        type={"goal"}
        data={{
          // start_date: formatDate(goal.start_date),
          start_date: goal.start_date,
          deadline: goal.deadline ? goal.deadline : null,
          goal_title: goal.title,
          goal_type: goal.type,
          goal_id: goal.id,
          countinggoal_count: goal.count,
          countinggoal_total: goal.total,
          is_shared: goal.is_shared,
          accountability_partners: accountabilityPartners,
          goal_participants: goal.goal_participants,
        }}
        setAccountabilityPartners={setAccountabilityPartners}
      />
      <SharedGoalParticipantsModal
        viewParticipantsModal={viewParticipantsModal}
        setViewParticipantsModal={setViewParticipantsModal}
        goal_id={goal.id}
      />
    </div>
  );
};

export default BasicGoal;
