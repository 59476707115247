import React, { useState } from "react";
// import "./Post.css";
import { BsHeartFill } from "react-icons/bs";
import { LiaComments } from "react-icons/lia";
import { AiFillDelete } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import JournalPost from "../JournalPost/JournalPost";
import HabitPost from "../HabitPost/HabitPost";
import GoalPost from "../GoalPost/GoalPost";
import { FaShare } from "react-icons/fa";
import { useSelector } from "react-redux";
import { MdOutlineReport } from "react-icons/md";
import { selectRole } from "../../features/userSlice";
import ReportModal from "../ReportModal";
import { request } from "../../util/util";
import { useDispatch } from "react-redux";
import parse from "html-react-parser";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { FaRegComment } from "react-icons/fa6";
import { PiShare } from "react-icons/pi";
import { CgProfile } from "react-icons/cg";
import { IoPersonCircleSharp } from "react-icons/io5";
import { HiDotsHorizontal } from "react-icons/hi";
import { UseSelector } from "react-redux/es/hooks/useSelector";
import { selectUsername } from "../../features/userSlice";
import { toast } from "react-toastify";
import { genTitle } from "../../util/util";

const Post = ({
  post,
  handleDelete,
  commentsButtonDisabled,
  display,
  section,
}) => {
  const username = useSelector(selectUsername);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [postState, setPostState] = useState(post);
  const [notificationSent, setNotificationSent] = useState(post.liked);
  const [liked, setLiked] = useState(post.liked);
  const role = useSelector(selectRole);
  const [reported, setReported] = useState(false);
  const [viewReportModal, setViewReportModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(
    display === "complete" ? true : false
  );
  const maxCharacters = 150;
  const displayText = isExpanded
    ? postState.body
    : postState.body.slice(0, maxCharacters);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLike = async (e) => {
    if (username === "") {
      // return navigate("/login");
      alert("Login First!");
    } else {
      let url = `posts/`;
      if (!liked) {
        url += "like/";
      } else {
        url += "removelike/";
      }
      url += `${postState.id}`;
      let shouldSendNotif = !liked & !notificationSent && section === "friends";
      const { status } = await request(
        liked ? "DELETE" : "POST",
        url,
        shouldSendNotif && {
          author_id: postState.author_id,
          post_title: postState.title,
        }
      );
      if (status) {
        if (shouldSendNotif) {
          setNotificationSent(true);
        }
        setPostState((prevPostState) => ({
          ...prevPostState,
          likes: liked
            ? parseInt(prevPostState.likes, 10) - 1
            : parseInt(prevPostState.likes, 10) + 1,
        }));
        setLiked(!liked);
      }
    }
  };

  function formatDate(dateString) {
    const postDate = new Date(dateString);
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const diffTime = Math.abs(now - postDate);

    if (postDate.toDateString() === now.toDateString()) {
      const diffSeconds = Math.round(diffTime / 1000);
      const diffMinutes = Math.round(diffTime / (1000 * 60));
      const diffHours = Math.round(diffTime / (1000 * 60 * 60));

      if (diffSeconds < 60) return `${diffSeconds} seconds ago`;
      if (diffMinutes < 60) return `${diffMinutes} minutes ago`;
      return `${diffHours} hours ago`;
    }

    if (postDate.getFullYear() === now.getFullYear()) {
      return postDate.toLocaleDateString(undefined, {
        month: "long",
        day: "numeric",
      });
    }

    return postDate.toLocaleDateString(undefined, {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }

  const date = postState.post_date ? formatDate(postState.post_date) : null;

  const post_actions_btn_size = 21;

  if (reported) {
    return null;
  }
  const postTitleUrl = `/comments/${postState.id}/${genTitle(postState.title)}`;

  const postActionClasses = `flex flex-row items-center gap-1`;
  const postActionActive = `fill-primary-color`;
  const postActionInactive = `fill-gray-400`;
  const postActionText = `text-secondary-color text-sm text-center`;
  return (
    <Link
      to={postTitleUrl}
      key={postState.key || postState.id}
      className={`flex flex-col gap-2 p-2 w-full ${
        !commentsButtonDisabled
          ? " hover:p-2.5 hover:bg-gray-100 hover:shadow-lg"
          : " hover:cursor-auto"
      } pb-3`}
      onClick={(e) => {
        if (commentsButtonDisabled) {
          e.preventDefault();
        }
      }}
      onDragStart={(e) => {
        if (commentsButtonDisabled) {
          e.preventDefault();
        }
      }}
    >
      <div className="flex flex-row justify-between items-center">
        <Link
          to={`/profile/${postState.username}`}
          className="flex flex-row gap-2 items-center hover:cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {postState.image != null ? (
            <img
              src={postState.image}
              className="size-16 rounded-full border-2 border-gray-300 shadow-lg"
            />
          ) : (
            <IoPersonCircleSharp className="fill-gray-200" size={70} />
          )}
          <div>
            <p className="font-semiBold text-md">{postState.username}</p>
            <p className="text-secondary-color text-sm font-light">{date}</p>
          </div>
        </Link>
        {(username === postState.username || role === "admin") && (
          <button
            className="h-fit relative"
            onClick={(e) => {
              setDropdownOpen(!dropdownOpen);
              e.preventDefault();
            }}
          >
            <HiDotsHorizontal />
            {dropdownOpen && (
              <div className="absolute top-[10px] right-[20%] mt-2 w-48 bg-white shadow-lg rounded-md z-50">
                <button
                  className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-red-500 hover:text-white flex flex-row gap-2 items-center font-light"
                  onClick={() => {
                    handleDelete(postState.id);
                  }}
                >
                  <AiFillDelete className="size-4" />
                  <span>Delete</span>
                </button>
              </div>
            )}
          </button>
        )}
      </div>
      <div
        to={postTitleUrl}
        className={`flex flex-col gap-2 ${
          display !== "complete" ? "hover:cursor-pointer" : null
        }`}
      >
        <p className="font-semiBold text-lg">{postState.title}</p>
        <p className="font-regular text-md leading-relaxed">
          {parse(displayText)}
          {display !== "complete" && postState.body.length > maxCharacters && (
            <button
              onClick={toggleReadMore}
              className="font-semiBold text-sm text-secondary-color bg-gray-200 rounded-lg p-0.5 mx-2"
            >
              {isExpanded ? "Read Less" : "Read More"}
            </button>
          )}
        </p>
        {postState.goal_data && (
          <div className="w-full md:w-5/6 lg:w-4/6 self-center mt-2">
            <GoalPost
              data={postState.goal_data}
              display={display}
              navigate={navigate}
              dispatch={dispatch}
            />
          </div>
        )}
      </div>
      <div className="flex flex-row gap-8 items-center justify-around lg:justify-start lg:px-0 w-full mt-4">
        <button
          className={`${postActionClasses} p-3`}
          onClick={(ev) => {
            handleLike();
            ev.preventDefault();
          }}
        >
          {liked ? (
            <FaHeart
              className={postActionActive}
              size={post_actions_btn_size}
            />
          ) : (
            <FaRegHeart
              className={postActionInactive}
              size={post_actions_btn_size}
            />
          )}
          <span className={postActionText}>{postState.likes}</span>
        </button>

        <div className={postActionClasses}>
          <FaRegComment
            className={postActionInactive}
            size={post_actions_btn_size}
          />
          <span className={postActionText}>{postState.comments}</span>
        </div>
        <button
          className={postActionClasses}
          onClick={(e) => {
            navigator.clipboard.writeText(
              `${window.location.origin}${postTitleUrl}`
            );
            toast.success("Link Copied to Clipboard!");
            e.preventDefault();
          }}
        >
          <PiShare
            className={postActionInactive}
            size={post_actions_btn_size}
          />
        </button>
      </div>
      <ReportModal
        viewReportModal={viewReportModal}
        setViewReportModal={setViewReportModal}
        post_id={postState.id}
        setReported={setReported}
      />
    </Link>
  );
};

export default Post;
