import React, { useState, useEffect } from "react";
import BasicGoal from "../BasicGoal/BasicGoal";
import "./MilestoneGoal.css";
import { useNavigate } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { request } from "../../util/util";

const MilestoneGoal = ({ goal }) => {
  const [viewTasks, setViewTasks] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState("");
  const [addTaskModal, setAddTaskModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Fetch data from the URL when viewTasks is set to true for the first time
  useEffect(() => {
    const fetchData = async () => {
      const { res, status } = await request("GET", `goal/tasks/${goal.id}`);
      if (status) {
        setTasks(res);
      }
    };

    if (viewTasks && tasks.length === 0) {
      fetchData();
    }
  }, [viewTasks, goal.id, tasks.length]);

  const isButtonDisabled = newTask.trim() === "";
  // Function to handle adding a new task
  const addTaskHandler = async () => {
    if (!isButtonDisabled) {
      const { res, status } = await request(
        "POST",
        "goal/tasks/new",

        {
          goal_id: goal.id,
          task: newTask,
        }
      );
      if (status) {
        setTasks([...tasks, { id: res, task: newTask, done: false }]);
        setNewTask("");
        setAddTaskModal(false);
      }
    }
  };

  const handleCheckboxChange = async (id, bool) => {
    const { status } = await request(
      "PUT",
      `goal/task/${id}/done`,

      {
        bool: !bool,
      }
    );
    if (status) {
      setTasks(
        tasks.map((currTask) =>
          id === currTask.id ? { ...currTask, done: !bool } : currTask
        )
      );
    }
  };

  const handleDeleteTask = async (task) => {
    const { status } = await request("DELETE", `goal/task/delete/${task.id}`);
    if (status) setTasks(tasks.filter((currTask) => task.id !== currTask.id));
  };

  return (
    <div className="flex flex-col gap-4 items-start w-full">
      <div className="w-full">
        <BasicGoal goal={goal} />

        <button
          className="text-sm text-primary-color font-semiBold"
          onClick={() => setViewTasks(!viewTasks)}
        >
          {viewTasks ? "Hide Tasks" : "View Tasks"}
        </button>
      </div>
      {viewTasks && (
        <div
          className={`flex flex-row gap-2 font-regular items-center w-full justify-center animate-slideInDown`}
        >
          <input
            className="w-full max-w-52 border-2 h-8 rounded-md outline-none p-1"
            type="text"
            value={newTask}
            onChange={(e) => setNewTask(e.target.value)}
          />
          <button
            onClick={addTaskHandler}
            className={`text-sm text-black p-1.5 rounded-md outline-none ${
              isButtonDisabled ? "bg-gray-100" : "bg-primary-color"
            }`}
            disabled={isButtonDisabled}
          >
            Add Task
          </button>
        </div>
      )}
      {viewTasks &&
        (tasks.length === 0 ? (
          <p className="m-auto text-center font-bold text-sm text-black mb-5">
            Insert some tasks
          </p>
        ) : (
          <div className={`w-full flex flex-col mb-24 gap-1 `}>
            {tasks.map((task) => (
              <div
                className={`${
                  task.done ? "bg-green-100" : "bg-gray-100"
                } flex flex-row justify-between p-4 rounded-md`}
                key={task.id}
              >
                <p className={`text-md font-regular text-black`}>{task.task}</p>
                <div className="flex flex-row gap-2">
                  <input
                    type="checkbox"
                    className="accent-secondary-color"
                    checked={task.done}
                    onChange={() => {
                      handleCheckboxChange(task.id, task.done);
                    }}
                  />
                  <button
                    onClick={() => {
                      handleDeleteTask(task);
                    }}
                  >
                    <AiFillDelete className="fill-black hover:fill-red-500" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export default MilestoneGoal;
