import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { FaPeopleGroup } from "react-icons/fa6";
import { RxDashboard } from "react-icons/rx";
import { TbTargetArrow } from "react-icons/tb";
import { TbCalendarRepeat } from "react-icons/tb";
import { BsJournal } from "react-icons/bs";

import { IoMdPeople, IoIosNotifications } from "react-icons/io";
import {
  IoSearchOutline,
  IoPersonSharp,
  IoLogOut,
  IoPersonCircleSharp,
} from "react-icons/io5";
import { FaPeoplePulling } from "react-icons/fa6";
import { CgProfile } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";
import { resetState as resetGoalState } from "../features/goalSlice";
import { resetState as resetFriendsState } from "../features/friendsSlice";
import { resetState as resetJournalState } from "../features/journalSlice";
import { resetState as resetHabitState } from "../features/habitSlice";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../util/util";
import {
  selectUsername,
  selectLoggedIn,
  toggleNavbar,
  selectProfileInfo,
} from "../features/userSlice";
import SocialLinks from "./SocialLinks";
import { selectUnreadNotifications } from "../features/notificationsSlice";
import { selectShowNavbar } from "../features/userSlice";
import { IoNotificationsOutline } from "react-icons/io5";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Navbar = () => {
  const navigate = useNavigate();
  const unread = useSelector(selectUnreadNotifications);
  const dispatch = useDispatch();
  const username = useSelector(selectUsername);
  const loggedIn = useSelector(selectLoggedIn);
  const [query, setQuery] = useState("");
  const showNav = useSelector(selectShowNavbar);
  const location = useLocation();
  const profileInfo = useSelector(selectProfileInfo);

  const handleSearch = (event) => {
    event.preventDefault();
    if (query.trim() !== "") {
      navigate(`/search?query=${query}`);
      setQuery("");
    }
  };

  const handleNavigation = (path) => {
    if (path) {
      navigate(path);
    }
    dispatch(toggleNavbar());
  };

  const navlinkClasses =
    "flex flex-row gap-2 items-center justify-start px-3 py-2 rounded-full font-regular  transition-colors duration-300 ";
  const activeLink = "bg-accent-color";
  const inactiveLink = "hover:text-primary-color";
  const spanClasses = "font-regular";
  const NavLinks = ({ display }) => <></>;
  console.log("profile info");
  console.log(profileInfo);
  return (
    <div
      style={{ height: "calc(100vh - 3.5rem)" }}
      className={
        "fixed h-full w-5/6 sm:w-1/2 md:w-80 bg-[rgb(252,252,252)] shadow-xl flex flex-col justify-between px-2 lg:px-5 pt-2 pb-4 transition-all duration-300 ease-in-out " +
        `${
          showNav
            ? "z-50 opacity-100 translate-x-0"
            : "opacity-0 -translate-x-full"
        } lg:opacity-100 lg:translate-x-0 lg:z-auto`
      }
    >
      <div className="flex flex-col gap-2.5 justify-start mt-3 w-auto ">
        <div className="flex flex-row gap-2 justify-between items-center">
          <div className="flex flex-row gap-2 items-center">
            {profileInfo && profileInfo.image ? (
              <img
                src={profileInfo.image}
                className="size-16 rounded-full border-2 border-gray-300 shadow-lg"
              />
            ) : (
              <IoPersonCircleSharp className="fill-gray-200" size={70} />
            )}
            <div className="flex flex-col justify-center">
              <p className="font-semiBold text-md">
                {loggedIn ? `@${username}` : "Guest"}
              </p>
              {loggedIn && (
                <button
                  onClick={() => {
                    if (location.pathname != `/profile/${username}`) {
                      handleNavigation(`/profile/${username}`);
                    }
                  }}
                  className={`text-xs  p-0 hover:text-primary-color transition duration-300 ${
                    location.pathname === `/profile/${username}`
                      ? "font-semiBold text-primary-color hover:cursor-default"
                      : "font-light text-black hover:cursor-pointer"
                  }`}
                >
                  View profile
                </button>
              )}
            </div>
          </div>
          {username && (
            <button
              onClick={() => {
                // navigate("/notifications");
                handleNavigation(`/notifications`);
              }}
              className="relative w-9 h-9 p-1 rounded-full bg-accent-color flex justify-center items-center hover:bg-white border-2 border-transparent hover:border-primary-color text-black hover:text-primary-color transition-colors duration-300"
            >
              <IoNotificationsOutline size={20} />
              {unread && (
                <span className="absolute -top-1 -right-1 w-3 h-3 bg-primary-color rounded-full"></span>
              )}
            </button>
          )}
        </div>
        <NavLink
          to="/dashboard"
          onClick={() => {
            handleNavigation();
          }}
          className={({ isActive }) =>
            `${navlinkClasses} ${isActive ? activeLink : inactiveLink}`
          }
        >
          <MdDashboard size={22} />
          <span className={spanClasses}>Dashboard</span>
        </NavLink>
        <NavLink
          to="/goals"
          onClick={() => {
            handleNavigation();
          }}
          className={({ isActive }) =>
            `${navlinkClasses} ${isActive ? activeLink : inactiveLink}`
          }
        >
          <TbTargetArrow size={22} />
          <span className={spanClasses}>Goals</span>
        </NavLink>
        <NavLink
          to="/accountability-center"
          onClick={() => {
            handleNavigation();
          }}
          className={({ isActive }) =>
            `${navlinkClasses} ${isActive ? activeLink : inactiveLink}`
          }
        >
          <FaPeoplePulling size={22} />

          <span className={spanClasses}>Accountability</span>
        </NavLink>
        <NavLink
          onClick={() => {
            handleNavigation();
          }}
          to="/social?section=community"
          className={({ isActive }) =>
            `${navlinkClasses} ${isActive ? activeLink : inactiveLink}`
          }
        >
          <FaPeopleGroup size={22} />
          <span className={spanClasses}>Social</span>
        </NavLink>
      </div>
      <div className="flex flex-col items-center gap-6 w-full">
        <button
          onClick={() => {
            if (loggedIn) {
              Cookies.remove("setNotifications");
              logout(dispatch, navigate);
            } else {
              navigate("/register");
            }
          }}
          className={`rounded-full text-center  bg-primary-color text-black font-semiBold w-full  py-1 gap-3 border-2 border-transparent hover:border-primary-color hover:text-primary-color hover:bg-white transition-colors duration-300`}
        >
          {loggedIn ? "Logout" : "Sign up"}
        </button>

        <SocialLinks />
      </div>
    </div>
  );
};

export default Navbar;
