import React, { useState, useEffect } from "react";
import "./AdminPanel.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectRole } from "../../features/userSlice";
import NotFound from "../../components/NotFound/NotFound";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const AdminPanel = () => {
  const [section, setSection] = useState("community");
  const [postIds, setPostIds] = useState([]);
  const role = useSelector(selectRole);

  useEffect(() => {
    const getPostIds = async () => {
      fetch(`${BASE_URL}/admin/reportedPosts/${section}`, {
        credentials: "include",
      })
        .then((res) => res.json())
        .then((postIdsRes) => {
          setPostIds(postIdsRes);
          // console.log(postIdsRes);
        });
    };

    getPostIds();
  }, [section]);

  const handleDelete = async (id) => {
    fetch(`${BASE_URL}/admin/removeReport/${id}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then(() => {
        setPostIds((prev) => prev.filter((report) => report.id !== id));
      })
      .catch((err) => alert(err));
  };

  if (role === "admin")
    return (
      <div>
        <ul>
          <li>
            <button
              onClick={() => {
                setSection("community");
              }}
            >
              community
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setSection("social");
              }}
            >
              Social feed
            </button>
          </li>
        </ul>
        <h2>section:{section}</h2>
        {postIds.map((post) => {
          return (
            <div style={{ marginBottom: 50 }}>
              <Link key={post.post_id} to={`/comments/${post.post_id}`}>
                id:{post.post_id}
              </Link>
              <p>reason:{post.reason}</p>

              <button
                onClick={() => {
                  handleDelete(post.id);
                }}
              >
                DELETE REPORT id : {post.id}
              </button>
            </div>
          );
        })}
      </div>
    );
  else {
    return <NotFound />;
  }
};

export default AdminPanel;
