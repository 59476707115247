import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../util/util";
import { toast } from "react-toastify";

const initialState = {
  goals: [],
  categories: [],
  types: ["basic", "milestone", "counting"],
  fetchedData: false,
};

// export const fetchGoalTypes = createAsyncThunk(
//   "goal/fetchTypes",
//   async ({ navigate }, { dispatch }) => {
//     // console.log(`${BASE_URL}/goal/types`);
//     // const jsonResponse = await fetch(`${BASE_URL}/goal/types`);
//     // const response = await jsonResponse.json();
//     // return response;
//     const { res, msg, status } = await request("GET", `goal/types`);
//   }
// );

export const fetchData = createAsyncThunk(
  "goal/fetchData",
  async (_, { rejectWithValue }) => {
    const { res, msg, status } = await request("GET", `goal/goals`);
    if (status) {
      // console.log(res);
      return res;
    } else {
      return rejectWithValue(msg);
    }
  }
);

export const deleteGoal = createAsyncThunk(
  "goal/deleteGoal",
  async ({ goal }, { rejectWithValue }) => {
    // console.log("in handledelete");
    // let id = goal.type;
    // let url;
    // if (id === 1) {
    //   url = "basic";
    // } else if (id === 2) {
    //   url = "milestone";
    // } else if (id === 3) {
    //   url = "counting";
    // }
    //---------------------
    // let url = `${goal.type}/${goal.id}`;
    // console.log(`fetch delete:${BASE_URL}/goal/delete/${url}`);
    // await fetch(`${BASE_URL}/goal/delete/${url}`, {
    //   method: "DELETE",
    //   credentials: "include",
    // });
    // return goal.id;
    const { res, msg, status } = await request(
      "DELETE",
      `goal/delete/${goal.id}`
    );
    if (status) {
      return goal.id;
    } else {
      rejectWithValue(msg);
    }
  }
);

export const addGoal = createAsyncThunk(
  "goal/addGoal",
  async ({ url, goal }, { rejectWithValue }) => {
    // console.log(goal);
    // const jsonResponse = await fetch(`${BASE_URL}/goal/${url}`, {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(goal),
    // });
    // const response = await jsonResponse.json();
    const { res, msg, status } = await request("POST", `goal/${url}`, {
      ...goal,
      selectedFriends: goal.selectedFriends
        ? goal.selectedFriends.map((friend) => friend.username)
        : null,
    });
    if (status) {
      return {
        id: res,
        title: goal.title,
        type: goal.type,
        is_shared: goal.is_shared,
        start_date: goal.start_date,
        deadline: goal.deadline ? goal.deadline : null,
        count: url === "new/counting" ? 0 : null,
        total: url === "new/counting" ? parseInt(goal.total, 10) : null,
        goal_participants: goal.selectedFriends ? goal.selectedFriends : [],
        goal_category_id: goal.goal_category_id
          ? parseInt(goal.goal_category_id, 10)
          : null,
        accountability_partners: [],
      };
    } else {
      return rejectWithValue(msg);
    }
  }
);
export const addGoalCategory = createAsyncThunk(
  "goal/addGoalCategory",
  async ({ category }, { rejectWithValue }) => {
    const { res, msg, status } = await request("POST", `goal/category/new`, {
      category,
    });
    if (status) {
      return res;
    } else {
      return rejectWithValue(msg);
    }
  }
);

export const goalSlice = createSlice({
  name: "goal",
  initialState,
  reducers: {
    //action.payload
    // setCurrentEntry: (state, action) => {},
    setCount: (state, action) => {
      const { goal_id, newCount } = action.payload;
      const goalToUpdate = state.goals.find((goal) => goal.id === goal_id);
      if (goalToUpdate) {
        // Use immer to mutate the existing state safely
        goalToUpdate.count = newCount;
      }
    },
    setFetchedData: (state, action) => {
      state.fetchedData = action.payload;
    },
    resetState: (state, action) => {
      return initialState;
    },
    changeGoalStatus: (state, action) => {
      state.goals = state.goals.map((goal) => {
        if (goal.id === action.payload.id) {
          return { ...goal, status: action.payload.status };
        }
        return goal;
      });
    },
  },
  extraReducers: (builder) => {
    //fulfilled, rejected,pending
    builder
      // .addCase(fetchGoalTypes.fulfilled, (state, action) => {
      //   state.types = action.payload;
      // })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.goals = action.payload.goals;
        state.categories = action.payload.categories;
      })
      .addCase(addGoal.fulfilled, (state, action) => {
        state.goals = [
          ...state.goals,
          { ...action.payload, status: "pending" },
        ];
        toast.success("Added Goal", { position: "top-center" });
      })
      .addCase(addGoalCategory.fulfilled, (state, action) => {
        state.categories = [...state.categories, action.payload.category];
        toast.success("Added Category", { position: "top-center" });
      })
      .addCase(deleteGoal.fulfilled, (state, action) => {
        state.goals = state.goals.filter((goal) => goal.id !== action.payload);
      });
  },
});

//SELECTOR
export const selectTypes = (state) => state.goal.types;
export const selectGoals = (state) => state.goal.goals;
export const selectFetchedData = (state) => state.goal.fetchedData;
export const selectCategories = (state) => state.goal.categories;

export const { setCount, setFetchedData, resetState, changeGoalStatus } =
  goalSlice.actions;
export default goalSlice.reducer;
