import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { request } from "../util/util";
import { toast } from "react-toastify";

const Comment = ({ comment, handleCommentDelete, username, role }) => {
  const [commentHasReplies, setCommentHasReplies] = useState(
    comment.reply_count > 0
  );
  const [viewReplies, setViewReplies] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const [reply, setReply] = useState("");
  const [replies, setReplies] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const handleReply = async () => {
    const { status, res } = await request(
      "POST",
      `posts/comment/${comment.post_id}`,
      {
        parentComment: comment.comment,
        author_id: comment.author_id,
        parentCommentId: comment.id,
        comment: reply,
      }
    );
    if (status) {
      setReplies([...replies, { username, comment: reply, id: res }]);
      setReply("");
      toast.success("Added reply");
      setIsReplying(false);
      if (!commentHasReplies) {
        setCommentHasReplies(true);
      }
    }
  };

  const fetchReplies = async () => {
    if (hasMore) {
      const { res, status } = await request(
        "GET",
        `posts/comment-replies/${comment.id}?page=${page}`
      );
      if (status) {
        // console.log("fetched replies");
        // console.log(res.replies);
        setReplies([...replies, ...res.replies]);
        // console.log("new replies");
        // console.log([...replies, ...res.replies]);
        if (res.replies.length < 5) {
          setHasMore(false);
        } else {
          setPage(page + 1);
        }
      }
    }
  };

  const handleReplyDelete = async (reply_id) => {
    request("DELETE", `posts/deletecomment/${reply_id}`).then(() => {
      setReplies(replies.filter((reply) => reply.id !== reply_id));
    });
  };

  return (
    <div key={comment.id} className="flex flex-col gap-1">
      <div className="flex flex-row justify-between items-center rounded-lg pl-2 shadow-sm bg-[rgba(0,0,0,0.0148)] pr-4">
        <div className="flex flex-col gap-1 p-1">
          <p className="font-semiBold text-md ">{comment.username}</p>
          <p className="font-regular text-sm text-gray-600 ml-2">
            {comment.comment}
          </p>
        </div>
        <div>
          {(comment.username === username || role === "admin") && (
            <button onClick={() => handleCommentDelete(comment.id)}>
              <AiFillDelete className="fill-black hover:fill-red-500 size-5" />
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-5">
        <button
          className="text-xs font-semiBold text-gray-500"
          onClick={() => {
            setIsReplying(!isReplying);
          }}
        >
          Reply
        </button>
        {commentHasReplies && (
          <button
            className="text-xs font-semiBold text-gray-500 flex flex-row items-center gap-1"
            onClick={() => {
              setViewReplies(!viewReplies);
              if (viewReplies === false && page === 1) {
                fetchReplies();
              }
            }}
          >
            {viewReplies ? (
              <>
                Hide Replies <FaAngleUp size={20} />
              </>
            ) : (
              <>
                View Replies <FaAngleDown size={20} />
              </>
            )}
          </button>
        )}
      </div>
      {isReplying && (
        <div className="flex mb-6  items-center w-full sm:w-5/6 md:w-4/6 lg:w-1/2 ">
          <input
            type="text"
            className="flex-grow w-full text-lg border border-gray-500 rounded-sm mr-1 text-md font-regular"
            value={reply}
            onChange={(e) => {
              setReply(e.target.value);
            }}
          />
          <button
            className="flex-shrink-0 bg-primary-color rounded-md px-2 py-1 text-md font-regular whitespace-nowrap"
            onClick={() => {
              handleReply();
            }}
          >
            Add Reply
          </button>
        </div>
      )}
      {viewReplies && (
        <div className="ml-5 mb-10 flex flex-col gap-3 mt-6">
          {replies.map((reply) => {
            return (
              <div
                className="flex flex-row justify-between items-center 
              border-gray-300 pl-2 shadow-sm bg-[rgba(0,0,0,0.01)]"
              >
                <div className="flex flex-col gap-1">
                  <p className="font-semiBold text-md text-gray-400">
                    {reply.username}
                  </p>
                  <p className="font-regular text-sm text-black ml-2">
                    {reply.comment}
                  </p>
                </div>
                <div>
                  {(reply.username === username || role === "admin") && (
                    <button onClick={() => handleReplyDelete(reply.id)}>
                      <AiFillDelete className="fill-black hover:fill-red-500 size-5" />
                    </button>
                  )}
                </div>
              </div>
            );
          })}
          {hasMore && (
            <button
              className="text-xs font-semiBold text-gray-500 flex flex-row"
              onClick={() => {
                fetchReplies();
              }}
            >
              View More <FaAngleDown size={20} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Comment;
