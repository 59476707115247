import React, { useState, useEffect } from "react";
import Modal from "../Modal";
import "./AddGoalCategoryModal.css";
import { request } from "../../util/util";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAllFriends } from "../../features/friendsSlice";
import { addGoalCategory, selectCategories } from "../../features/goalSlice";

const AddGoalCategoryModal = ({ view, setView }) => {
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const [input, setInput] = useState("");
  const [error, setError] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    // const {res,status,msg}=await request("POST",'/')
    if (
      [...categories, { category: "All" }, { category: "Shared" }]
        .map((category) => category.category.toLowerCase())
        .includes(input.toLowerCase())
    ) {
      setError(true);
    } else {
      if (error) setError(false);
      dispatch(addGoalCategory({ category: input }));
      setInput("");
    }
  };

  const isButtonDisabled = input.trim() === "";

  return (
    <Modal show={view} setShow={setView}>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center gap-4"
      >
        <div className="flex flex-row justify-center gap-4 items-center">
          <p className="font-semiBold text-black text-md">Category:</p>
          <input
            type="text"
            className="w-full md:w-96 p-1 rounded-md border-2 border-gray-300 "
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          {error && (
            <p className="text-red-500 text-sm">Category already found</p>
          )}
        </div>

        <button
          type="submit"
          className={` ${
            isButtonDisabled ? "bg-gray-200" : "bg-primary-color"
          } px-4 py-1 h-fit font-regular rounded-md`}
          disabled={isButtonDisabled}
        >
          Save
        </button>
      </form>
    </Modal>
  );
};

export default AddGoalCategoryModal;
