import React, { useState, useEffect } from "react";
import "./GoalTracking.css";
import MilestoneGoal from "../../components/MilestoneGoal/MilestoneGoal";
import CountingGoal from "../../components/CountingGoal/CountingGoal";
import BasicGoal from "../../components/BasicGoal/BasicGoal";
import { useDispatch, useSelector } from "react-redux";
import { selectUsername, selectLoggedIn } from "../../features/userSlice";
import { getFriendsData, selectAllFriends } from "../../features/friendsSlice";
import { MdEdit } from "react-icons/md";
import {
  // fetchGoalTypes,
  selectCategories,
  fetchData,
  selectTypes,
  selectGoals,
  addGoal,
  selectFetchedData,
  setFetchedData,
  resetState,
} from "../../features/goalSlice";
import { useNavigate } from "react-router-dom";
import AddGoalModal from "../../components/AddGoalModal/AddGoalModal";
import AddGoalCategoryModal from "../../components/AddGoalCategoryModal/AddGoalCategoryModal";
import { Helmet } from "react-helmet-async";
import SEO from "../../components/SEO";
import { titleClasses } from "../../util/globals";
import { AiFillDelete } from "react-icons/ai";
import { toast } from "react-toastify";

// const BASE_URL = process.env.REACT_APP_BASE_URL;

const GoalTracking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const username = useSelector(selectUsername);
  const types = useSelector(selectTypes);
  const goals = useSelector(selectGoals);
  const fetchedData = useSelector(selectFetchedData);
  const [isAddingGoal, setIsAddingGoal] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [title, setTitle] = useState("");
  const [deadline, setDeadline] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  // const [customInputValue, setCustomInputValue] = useState(""); // Add more state variables for custom inputs based on the type
  // const [types, setTypes] = useState(["basic", "milestone", "counting"]);
  const [inputValues, setInputValues] = useState([""]); // Initial state with one input
  const [selectedDeadline, setSelectedDeadline] = useState("");
  const [targetValue, setTargetValue] = useState(0);
  const [selectedFriends, setSelectedFriends] = useState([]);
  // const [friendsList, setFriendsList] = useState([]);
  const friendsList = useSelector(selectAllFriends);
  const [status, setStatus] = useState("in progress");
  const categories = useSelector(selectCategories);
  const [selectedCategory, setSelectedCategory] = useState({ category: "All" });
  const [showAddGoalModal, setShowAddGoalModal] = useState(false);
  const [showAddGoalCategoryModal, setShowAddGoalCategoryModal] =
    useState(false);
  useEffect(() => {
    if (!fetchedData) {
      // dispatch(fetchGoalTypes());
      dispatch(fetchData({ navigate }));
      dispatch(setFetchedData(true));
      // fetch(`http://localhost:4000/profile/${username}/friends/all`, {
      //   credentials: "include",
      // }).then(async (jsonRes) => {
      //   let res = await jsonRes.json();
      //   setFriendsList(res.arr);
      // });
      // dispatch(getFriendsData({ tab: "all", username }));
    }
  }, [dispatch, fetchedData, username]);

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  // const handleCustomInputChange = (e) => {
  //   setCustomInputValue(e.target.value);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your logic to handle form submission here
    let goal = {};
    let url;
    const currentDate = new Date().toISOString();
    goal.start_date = currentDate;
    goal.title = title;
    goal.deadline = selectedDeadline;
    goal.is_shared = isChecked;
    if (isChecked) {
      if (selectedFriends.length > 0) {
        goal.selectedFriends = selectedFriends;
      }
    }

    // goal.goal_type_id = types.find((obj) => obj.type === selectedType).id;
    goal.type = selectedType;
    if (selectedType === "milestone") {
      goal.tasksArr = inputValues.slice(0, -1).map((value) => {
        return { title: value };
      });
      url = `new/milestone`;
    } else if (selectedType === "counting") {
      goal.total = targetValue;

      url = `new/counting`;
    } else {
      url = `new/basic`;
    }

    resetForm();
    await dispatch(addGoal({ url, goal, navigate }));
  };

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;

    // Add an empty string for the next input field if the last input is filled
    if (index === inputValues.length - 1 && value !== "" && index < 4) {
      newInputValues.push("");
    }

    setInputValues(newInputValues);
  };

  const handleDateChange = (e) => {
    setSelectedDeadline(e.target.value);
  };

  const handleCheckboxChange = (friendUsername) => {
    const updatedSelectedFriends = [...selectedFriends];

    if (updatedSelectedFriends.includes(friendUsername)) {
      // Remove friend if already selected
      updatedSelectedFriends.splice(
        updatedSelectedFriends.indexOf(friendUsername),
        1
      );
    } else {
      updatedSelectedFriends.push(friendUsername);
    }

    setSelectedFriends(updatedSelectedFriends);
  };
  const resetForm = () => {
    setIsAddingGoal(false);
    setTitle("");
    setTargetValue(0);
    setInputValues([""]);
    setSelectedFriends([]);
    setSelectedDeadline(null);
    setIsChecked(false);
    setSelectedType("");
  };

  const isButtonDisabled =
    !title || (selectedType === "counting" && !targetValue);

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
  };

  return (
    <div className="flex flex-col gap-10 lg:gap-8 w-full">
      {/* <Helmet>
        <title>Track and Achieve Your Goals - Friengle Goal Tracking</title>
        <meta
          name="description"
          content="Set, track, and achieve your personal and professional goals with Friengle. Join our community to stay motivated and reach new heights together."
        />
      </Helmet> */}
      <SEO
        title={"Goal Tracking - Friengle"}
        description={"Set, track, and achieve your goals with Friengle."}
      />
      <div className="flex flex-row justify-between gap-4">
        <h1 className={titleClasses}>Set, Track, and Achieve Goals</h1>
        <div className="flex flex-row gap-2">
          <button
            onClick={() => {
              setShowAddGoalCategoryModal(true);
            }}
            className="bg-gray-200 p-2 rounded-lg font-regular text-sm lg:text-base"
          >
            Add Category
          </button>
          <button
            onClick={() => {
              setShowAddGoalModal(true);
            }}
            className="bg-primary-color p-2 rounded-lg font-regular text-sm lg:text-base"
          >
            New Goal
          </button>
        </div>
      </div>

      <div className="w-full overflow-x-auto  flex flex-row items-end gap-2 pb-1">
        {[{ category: "All" }, ...categories].map((category) => {
          let active = selectedCategory.category === category.category;
          return (
            <div
              className={`flex flex-row gap-1 duration-300 ${
                active && category.category !== "All"
                  ? "mx-4 md:mx-8"
                  : active
                  ? "mr-2 md:mr-4"
                  : ""
              }`}
            >
              <button
                className={` text-md  rounded-sm text-sm duration-300 ${
                  active
                    ? " font-semiBold border-b-4 border-green-400 bg-gray-100 py-3 px-5 "
                    : " font-light border-b-2 border-gray-300 py-1.5 px-3"
                }`}
                onClick={() => {
                  setSelectedCategory(category);
                }}
              >
                {category.category}
              </button>
              {/* {active && category.category !== "All" && (
                <div className="flex flex-col justify-center gap-2">
                  <button>
                    <MdEdit className="fill-black hover:fill-yellow-600" />
                  </button>
                  <button>
                    <AiFillDelete className="fill-black hover:fill-red-600" />
                  </button>
                </div>
              )} */}
            </div>
          );
        })}
      </div>

      <div className="self-center w-full max-w-3xl bg-gray-200 rounded-full flex justify-between p-1">
        {["In Progress", "Pending", "Completed"].map((item, index) => {
          let itemToLower = item.toLowerCase();
          return (
            <button
              className={`basis-2/6  transition-colors duration-300 py-1 rounded-full text-md ${
                status === itemToLower ? "bg-white font-semiBold" : "font-light"
              }`}
              onClick={() => {
                handleStatusChange(itemToLower);
              }}
              disabled={status === itemToLower}
            >
              {item}
            </button>
          );
        })}
      </div>
      <div className="mt-4 mb-8 flex flex-col gap-4 w-full lg:w-5/6 lg:max-w-2xl self-center">
        {types.length > 0 &&
          goals.length > 0 &&
          goals
            .filter(
              (goal) =>
                (selectedCategory.category !== "All"
                  ? goal.goal_category_id === selectedCategory.id
                  : true) && goal.status === status
            )
            .map((goal) => {
              if (goal.type === "milestone") {
                return <MilestoneGoal key={goal.id} goal={goal} />;
              } else if (goal.type === "counting") {
                return <CountingGoal key={goal.id} goal={goal} />;
              } else {
                return <BasicGoal key={goal.id} goal={goal} />;
              }
            })}
      </div>
      <AddGoalModal view={showAddGoalModal} setView={setShowAddGoalModal} />
      <AddGoalCategoryModal
        view={showAddGoalCategoryModal}
        setView={setShowAddGoalCategoryModal}
      />
    </div>
  );
};

export default GoalTracking;
