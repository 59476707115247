import { router } from "../App";
import { store } from "../app/store";
import { toast } from "react-toastify";
const genURI = (URN) => `https://api.friengle.com/${URN}`;
// const genURI = (URN) => `http://localhost:4000/${URN}`;
export function genTitle(title) {
  title = title.toLowerCase().replace(/[^a-z0-9\s]/g, "");
  return title
    .split(" ")
    .filter((word) => word.trim())
    .slice(0, 10)
    .join("-");
}
export const logout = async () => {
  fetch(genURI("logout"), {
    method: "POST",
    credentials: "include",
  }).then(() => {
    router.navigate("/login");
    store.dispatch({ type: "USER_LOGOUT" });
  });
};

export const formatDate = (dateString) => {
  const dateObject = new Date(dateString);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return dateObject.toLocaleDateString(undefined, options);
};

export function formatDateRelative(dateString) {
  const postDate = new Date(dateString);
  const now = new Date();
  const oneDay = 24 * 60 * 60 * 1000;
  const diffTime = Math.abs(now - postDate);

  if (postDate.toDateString() === now.toDateString()) {
    const diffSeconds = Math.round(diffTime / 1000);
    const diffMinutes = Math.round(diffTime / (1000 * 60));
    const diffHours = Math.round(diffTime / (1000 * 60 * 60));

    if (diffSeconds < 60) return `${diffSeconds} seconds ago`;
    if (diffMinutes < 60) return `${diffMinutes} minutes ago`;
    return `${diffHours} hours ago`;
  }

  if (postDate.getFullYear() === now.getFullYear()) {
    return postDate.toLocaleDateString(undefined, {
      month: "long",
      day: "numeric",
    });
  }

  return postDate.toLocaleDateString(undefined, {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export const request = async (
  method,
  URN,
  data = null,
  multiformData = false
) => {
  try {
    // console.log(`URN: ${URN}`);
    // console.log(`data: ${data}`);
    let requestArgument;
    switch (method) {
      case "GET": {
        requestArgument = {
          method: "GET",
          credentials: "include",
        };
        break;
      }
      case "PUT":
      case "POST":
      case "DELETE": {
        {
          if (data) {
            if (multiformData) {
              const formData = new FormData();
              for (const key in data) {
                console.log(`key:${key},data: ${data[key]}`);
                formData.append(key, data[key]);
              }

              requestArgument = {
                method: method,
                credentials: "include",
                body: formData,
              };
            } else {
              requestArgument = {
                method,
                credentials: "include",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify(data),
              };
            }
          } else {
            requestArgument = {
              method,
              credentials: "include",
            };
          }
          break;
        }
      }
      default: {
        requestArgument = {};
        break;
      }
    }
    // console.log("req");
    const req = await fetch(genURI(URN), requestArgument);
    // console.log(req);
    if (req.status === 401 || req.status === 403) {
      logout();
    } else if (req.status === 500 || req.status === 400) {
      // console.log("status 400 500,status false");
      return { status: false, msg: "Error" };
    } else if (req.status === 204) {
      // console.log("204, status true");
      return { status: true };
    } else {
      // console.log("status true");
      const res = await req.json();
      // console.log("res");
      // console.log(res);
      return { res, status: true };
    }
  } catch (err) {
    // console.log("err");
    return { status: false, msg: "Error" };
  }
};
