import React, { useState, useEffect } from "react";
import "./About.css";
import { Link, useNavigate } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import aboutImg from "../../assets/images/about.png";
// import { Helmet } from "react-helmet-async";
import SEO from "../../components/SEO";
import SocialLinks from "../../components/SocialLinks";
import { selectLoggedIn } from "../../features/userSlice";
import { useSelector } from "react-redux";
import {
  FiCheckCircle,
  FiTrendingUp,
  FiClipboard,
  FiFlag,
  FiAward,
  FiUsers,
  FiShare2,
  FiMessageCircle,
  FiTarget,
  FiArrowLeft,
  FiArrowRight,
  FiStar,
  FiUserCheck,
} from "react-icons/fi";

const items = [
  {
    icon: FiTarget,
    title: "Goal Tracking",
    text: "Track your progress effortlessly.",
  },
  {
    icon: FiUsers,
    title: "Shared Goals",
    text: "Add friends to your goals.",
  },
  {
    icon: FiUserCheck,
    title: "Accountability Partners",
    text: "Set accountability partners for a goal.",
  },
  {
    icon: FiShare2,
    title: "Social Feed",
    text: "Share updates, milestones, and insights.",
  },
  {
    icon: FiMessageCircle,
    title: "Community",
    text: "Dive into discussions covering personal growth and goal achievement.",
  },
];
const About = () => {
  const [opacity, setOpacity] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const loggedIn = useSelector(selectLoggedIn);

  const navigate = useNavigate();

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + items.length) % items.length
    );
  };

  const getVisibleItems = () => {
    const visibleItems = [];
    for (let i = 0; i < 3; i++) {
      visibleItems.push(items[(currentIndex + i) % items.length]);
    }
    return visibleItems;
  };

  const visibleItems = getVisibleItems();

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000);
    return () => clearInterval(interval);
  }, []);

  const textClasses = "text-black text-md mt-2";
  const textFont = "font-regular";
  const emphasizedTextClasses =
    "text-secondary-color text-md lg:text-lg mb-2 font-bold";
  const h2Classes = "text-2xl font-semiBold mb-5";

  if (loggedIn) navigate("/dashboard");

  return (
    <>
      {/* <Helmet>
        <title></title>
        <meta
          name="description"
          content="Learn how Friengle helps individuals and communities achieve their goals through social support and innovative tracking tools. Discover our mission and values."
        />
      </Helmet> */}
      <SEO
        title={"Friengle"}
        description={
          "Learn how Friengle helps individuals and communities achieve their goals through social support and innovative tracking tools. Discover our mission and values."
        }
      />
      <AuthHeader page="about" />
      <div className="min-h-[90vh] flex flex-col items-center w-full justify-center sm:w-5/6 px-4 sm:px-0 lg:w-4/6 max-w-5xl m-auto gap-8 leading-6 pb-4 pt-4 md:pt-6 ">
        <div className="">
          <h1 className="text-2xl mb-6 font-bold text-center">
            With Friengle, You Don't Have to Do It Alone
          </h1>
          <p className={`${emphasizedTextClasses} text-center`}>
            We help you stay consistent with your goals by leveraging{" "}
            <em>social accountability</em> and <em>community support</em>.
          </p>
        </div>
        <div className="mt-1 lg:mt-0 relative overflow-hidden w-full h-[30vh] sm:h-[40vh] md:h-[50vh] lg:h-[60vh]  2xl:h-[50vh]  bg-gray-50">
          <iframe
            className="absolute top-0 left-0 w-full h-full"
            src={`https://www.youtube.com/embed/PJ2E7bVJuqY`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded YouTube"
          />
        </div>
        <p className={`text-regular text-black font-regular text-center`}>
          This is still in development, many features and improvements are on
          the way (gamification, leaderboard, challenges, ui/ux
          improvements...). Register before our official launch to secure free
          access, even if we introduce paid plans later. Thanks for believing in
          this project and your future as you work towards your goals! 💚
        </p>
        {/* <div className="flex flex-row items-center relative w-full">
          <button
            className="bg-gray-200 text-gray-600 p-3 rounded-full hover:bg-primary-color hover:text-white transition duration-300"
            onClick={prevSlide}
          >
            <FiArrowLeft className="size-5" />
          </button>
          <div className="flex flex-row items-center justify-around relative w-full overflow-x-hidden">
            {visibleItems.map((item, index) => (
              <div
                key={index}
                className="flex-shrink-0 w-[70%] sm:w-[48%] md:w-[28%] min-h-[180px] p-4 bg-white shadow-md rounded-lg mx-2"
              >
                <item.icon className="size-8 mb-2" />
                <h3 className="text-lg font-regular text-black">
                  {item.title}
                </h3>
                <p className="text-md text-secondary-color font-light">
                  {item.text}
                </p>
              </div>
            ))}
          </div>

          <button
            className="bg-gray-200 text-gray-600 p-3 rounded-full hover:bg-primary-color hover:text-white transition duration-30"
            onClick={nextSlide}
          >
            <FiArrowRight className="size-5" />
          </button>
        </div> */}
      </div>
    </>
  );
};

export default About;
