import React, { useEffect, useState } from "react";
import { formatDateRelative, request } from "../util/util";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNotifications,
  selectUnreadNotifications,
  setReadNotifications,
  changeReadStatus,
} from "../features/notificationsSlice";
import { titleClasses } from "../util/globals";
import {
  FiUser,
  FiUserPlus,
  FiMessageSquare,
  FiThumbsUp,
  FiMessageCircle,
  FiCornerUpRight,
  FiUserCheck,
  FiBell,
} from "react-icons/fi";
import SEO from "../components/SEO";
import { useNavigate } from "react-router-dom";
import { selectUsername } from "../features/userSlice";

const Notifications = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const username = useSelector(selectUsername);
  const notifications = useSelector(selectNotifications);
  const unread = useSelector(selectUnreadNotifications);

  useEffect(() => {
    if (unread) dispatch(setReadNotifications());
    return () => {
      if (unread) {
        dispatch(changeReadStatus());
      }
    };
  }, [unread]);

  const getNotificationData = (notification) => {
    const iconClasses = "text-black size-5";
    switch (notification.type) {
      case "goal partner":
        return {
          icon: <FiUser className={iconClasses} />,
          handleNotificationPress: () => {
            navigate("/goals");
          },
        };
      case "accountability partner":
        return {
          icon: <FiUserPlus className={iconClasses} />,
          handleNotificationPress: () => {
            navigate("/accountability-center");
          },
        };
      case "accountability message":
        return {
          icon: <FiMessageSquare className={iconClasses} />,
          handleNotificationPress: () => {
            navigate("/accountability-center");
          },
        };
      case "post like":
        return {
          icon: <FiThumbsUp className={iconClasses} />,
          handleNotificationPress: () => {
            navigate(
              notification.data.post_id != null
                ? `/comments/${notification.data.post_id}`
                : "/social?section=community"
            );
          },
        };
      case "post comment":
        return {
          icon: <FiMessageCircle className={iconClasses} />,
          handleNotificationPress: () => {
            navigate(
              notification.data.post_id != null
                ? `/comments/${notification.data.post_id}`
                : "/social?section=community"
            );
          },
        };
      case "comment reply":
        return {
          icon: <FiCornerUpRight className={iconClasses} />,
          handleNotificationPress: () => {
            navigate(
              notification.data.post_id != null
                ? `/comments/${notification.data.post_id}`
                : "/social?section=community"
            );
          },
        };
      case "friend request":
        return {
          icon: <FiUserPlus className={iconClasses} />,
          handleNotificationPress: () => {
            navigate(`/profile/${username}`);
          },
        };
      case "friend request accepted":
        return {
          icon: <FiUserCheck className={iconClasses} />,
          handleNotificationPress: () => {
            navigate(`/profile/${username}`);
          },
        };
      default:
        return {
          icon: <FiBell className={iconClasses} />,
          handleNotificationPress: () => {},
        };
    }
  };
  return (
    <div>
      <SEO
        title="Notifications - Friengle"
        description="Stay updated with the latest notifications, including new messages, friend requests, and activity alerts."
      />
      <h1 className={titleClasses}>Notifications</h1>
      <div className="flex flex-col my-4 gap-5">
        {notifications.length > 0 ? (
          notifications.map((notification, index) => {
            const notificationData = getNotificationData(notification);
            return (
              <div
                // className={`flex flex-row gap-3 items-center bg-accent-color`}
                className={`flex flex-row gap-3 items-center hover:cursor-pointer hover:bg-gray-200 ${
                  notification.read ? "bg-white" : "bg-accent-color"
                }`}
                key={index}
                onClick={notificationData.handleNotificationPress}
              >
                <div className={"bg-gray-100 p-4 rounded-lg"}>
                  {notificationData.icon}
                </div>
                <div className="flex flex-col justify-center">
                  <p className="text-black text-md font-semiBold ">
                    {notification.message}
                  </p>
                  <p className="text-sm text-secondary-color font-regular">
                    {formatDateRelative(notification.created_at)}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-md text-black font-regular mt-5">
            You have no new notifications. Encourage your friends to stay
            connected and keep the updates coming!
          </p>
        )}
      </div>
    </div>
  );
};

export default Notifications;
