import React from "react";
import { Helmet } from "react-helmet-async";
import logo from "../assets/logo-cropped.png";
const keyword_extractor = require("keyword-extractor");

const SEO = ({ title, description, imageUrl = null,keywords=null }) => {
  const extractKeywords = (text) => {
    const extractionResult = keyword_extractor.extract(text, {
      language: "english",
      remove_digits: true,
      return_changed_case: true,
      remove_duplicates: true,
    });

    return extractionResult.join(", ");
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content={extractKeywords(`friengle ${title || ""} ${description || ""} ${keywords || ""}`)}
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl ?? logo} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:site_name" content="friengle" />
      <meta property="og:locale" content="en_US" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl ?? logo} />
    </Helmet>
  );
};

export default SEO;
