import React, { useEffect, useState } from "react";
import "./Search.css";
import { Link, useNavigate } from "react-router-dom";
import { request } from "../../util/util";
import Post from "../../components/Post/Post";
import SEO from "../../components/SEO";
import { IoPersonCircleSharp } from "react-icons/io5";

const User = ({ item }) => {
  return (
    <Link
      to={`/profile/${item.username}`}
      key={item.id}
      className="flex justify-start items-center gap-2 text-xl tracking-wide "
    >
      <IoPersonCircleSharp className="size-11 fill-accent-color" />
      <p>{item.username}</p>
    </Link>
  );
};
const Search = () => {
  const [results, setResults] = useState([]);
  const [resultType, setResultType] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const searchTermFromUrl = urlParams.get("query");
  useEffect(() => {
    if (searchTermFromUrl) {
      fetchData(searchTermFromUrl);
    }
  }, [searchTermFromUrl]);

  const fetchData = async (term) => {
    setLoading(true);
    try {
      const { res, status } = await request("GET", `search?query=${term}`);
      if (status) {
        setResultType(res.type);
        setResults(res.results);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (postId) => {
    const { res, status } = await request(
      "DELETE",
      `posts/deletepost/${postId}`
    );
    if (status) {
      setResults(results.filter((post) => post.id !== postId));
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <SEO
        title={"Find and Connect: Search Users and Posts on Friengle"}
        description={
          "Explore Friengle through our dynamic search. Whether you're discovering inspiring users or engaging posts, your next connection starts here. Dive into a world of possibilities."
        }
      />
      {!loading && results.length > 0 ? (
        resultType === "users" ? (
          results.map((user) => {
            return <User item={user} />;
          })
        ) : (
          results.map((post) => {
            return (
              <Post
                key={post.id}
                post={post}
                handleDelete={handleDelete}
                commentsButtonDisabled={false}
                display={"preview"}
              />
            );
          })
        )
      ) : (
        <p className="font-semiBold text-md text-center">
          No results... Check for spelling mistakes
        </p>
      )}
    </div>
  );
};

export default Search;
