import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../util/util";

const initialState = {
  habits: [],
  habitTitle: "",
  frequency: 6,
  frequencyType: "daily",
};

export const fetchHabits = createAsyncThunk(
  "habit/fetchHabits",
  async ({}, { rejectWithValue }) => {
    const { res, status, msg } = await request("GET", "habit/habits");
    if (status) {
      return res;
    } else {
      return rejectWithValue(msg);
    }
  }
);

export const addHabit = createAsyncThunk(
  "habit/addHabit",
  async ({ navigate, habit }, { dispatch, rejectWithValue }) => {
    const { res, status, msg } = await request(
      "POST",
      "habit/new",

      habit
    );
    if (status) {
      const newHabit = {
        ...habit,
        id: res,
        streak: 0,
        weekly_count: 0,
        count: 0,
        last_completion_date: null,
      };
      return newHabit;
    } else {
      return rejectWithValue(msg);
    }
  }
);

// export const incrementCount = createAsyncThunk(
//   "habit/incrementCount",
//   async (habit_id, { rejectWithValue }) => {
//     const response = await fetch(
//       `${BASE_URL}/habit/incrementcount/${habit_id}`,
//       {
//         credentials: "include",
//         method: "PUT",
//       }
//     );

//     if (response.ok) {
//       // Handle the response status 200 here (or any other successful status code)
//       return habit_id;
//     } else {
//       // Handle other status codes (e.g., 404, 500) here
//       // You can use rejectWithValue to send specific data to the rejected action
//       return rejectWithValue(response.status);
//     }
//   }
// );
// export const decrementCount = createAsyncThunk(
//   "habit/decrementCount",
//   async (habit_id, { rejectWithValue }) => {
//     const response = await fetch(
//       `${BASE_URL}/habit/decrementcount/${habit_id}`,
//       {
//         credentials: "include",
//         method: "PUT",
//       }
//     );

//     if (response.ok) {
//       // Handle the response status 200 here (or any other successful status code)
//       return habit_id;
//     } else {
//       // Handle other status codes (e.g., 404, 500) here
//       // You can use rejectWithValue to send specific data to the rejected action
//       return rejectWithValue(response.status);
//     }
//   }
// );

// export const resetCount = createAsyncThunk(
//   "habit/resetCount",
//   async (habit_id, { rejectWithValue }) => {
//     const response = await fetch(`${BASE_URL}/habit/resetcount/${habit_id}`, {
//       credentials: "include",
//       method: "PUT",
//     });
//     if (response.ok) {
//       // Handle the response status 200 here (or any other successful status code)
//       return habit_id;
//     } else {
//       // Handle other status codes (e.g., 404, 500) here
//       // You can use rejectWithValue to send specific data to the rejected action
//       return rejectWithValue(response.status);
//     }
//   }
// );

// export const incrementStreak = createAsyncThunk(
//   "habit/incrementStreak",
//   async (habit_id, { rejectWithValue }) => {
//     const response = await fetch(
//       `${BASE_URL}/habit/incrementstreak/${habit_id}`,
//       {
//         credentials: "include",
//         method: "PUT",
//       }
//     );

//     if (response.ok) {
//       // Handle the response status 200 here (or any other successful status code)
//       return habit_id;
//     } else {
//       // Handle other status codes (e.g., 404, 500) here
//       // You can use rejectWithValue to send specific data to the rejected action
//       return rejectWithValue(response.status);
//     }
//   }
// );
// export const decrementStreak = createAsyncThunk(
//   "habit/decrementStreak",
//   async (habit_id, { rejectWithValue }) => {
//     const response = await fetch(
//       `${BASE_URL}/habit/decrementstreak/${habit_id}`,
//       {
//         credentials: "include",
//         method: "PUT",
//       }
//     );

//     if (response.ok) {
//       // Handle the response status 200 here (or any other successful status code)
//       return habit_id;
//     } else {
//       // Handle other status codes (e.g., 404, 500) here
//       // You can use rejectWithValue to send specific data to the rejected action
//       return rejectWithValue(response.status);
//     }
//   }
// );

export const resetStreak = createAsyncThunk(
  "habit/resetStreak",
  async ({ navigate, habit_id }, { rejectWithValue, dispatch }) => {
    const { status, res, msg } = await request(
      "PUT",
      `habit/resetstreak/${habit_id}`
    );
    if (status) {
      return habit_id;
    } else {
      return rejectWithValue(msg);
    }
  }
);

// export const incrementWeeklyCount = createAsyncThunk(
//   "habit/incrementWeeklyCount",
//   async (habit_id, { rejectWithValue }) => {
//     const response = await fetch(
//       `${BASE_URL}/habit/incrementweekly/${habit_id}`,
//       {
//         credentials: "include",
//         method: "PUT",
//       }
//     );

//     if (response.ok) {
//       // Handle the response status 200 here (or any other successful status code)
//       return habit_id;
//     } else {
//       // Handle other status codes (e.g., 404, 500) here
//       // You can use rejectWithValue to send specific data to the rejected action
//       return rejectWithValue(response.status);
//     }
//   }
// );
// export const decrementWeeklyCount = createAsyncThunk(
//   "habit/decrementWeeklyCount",
//   async (habit_id, { rejectWithValue }) => {
//     const response = await fetch(
//       `${BASE_URL}/habit/decrementweekly/${habit_id}`,
//       {
//         credentials: "include",
//         method: "PUT",
//       }
//     );

//     if (response.ok) {
//       // Handle the response status 200 here (or any other successful status code)
//       return habit_id;
//     } else {
//       // Handle other status codes (e.g., 404, 500) here
//       // You can use rejectWithValue to send specific data to the rejected action
//       return rejectWithValue(response.status);
//     }
//   }
// );

export const resetWeeklyCount = createAsyncThunk(
  "habit/resetWeeklyCount",
  async ({ navigate, habit_id }, { rejectWithValue, dispatch }) => {
    const { res, msg, status } = await request(
      "PUT",
      `habit/resetweekly/${habit_id}`
    );
    if (status) {
      return habit_id;
    } else {
      return rejectWithValue(msg);
    }
  }
);

export const deleteHabit = createAsyncThunk(
  "habit/deleteHabit",
  async ({ navigate, habit_id }, { rejectWithValue, dispatch }) => {
    const { res, msg, status } = await request(
      "DELETE",
      `habit/delete/${habit_id}`
    );
    if (status) {
      return habit_id;
    } else {
      return rejectWithValue(msg);
    }
  }
);

export const habitDone = createAsyncThunk(
  "habit/habitDone",
  async ({ navigate, habit_id }, { rejectWithValue, dispatch }) => {
    const { res, msg, status } = await request("PUT", `habit/done/${habit_id}`);
    if (status) {
      return res;
    } else {
      return rejectWithValue(msg);
    }
  }
);

export const undoHabit = createAsyncThunk(
  "habit/undoHabit",
  async ({ navigate, habit_id }, { rejectWithValue, dispatch }) => {
    const { res, msg, status } = await request("PUT", `habit/undo/${habit_id}`);
    if (status) {
      return res;
    } else {
      return rejectWithValue(msg);
    }
  }
);

export const habitSlice = createSlice({
  name: "habit",
  initialState,
  reducers: {
    setFetchedData: (state, action) => {
      state.fetchedData = action.payload;
    },
    setHabitTitle: (state, action) => {
      state.habitTitle = action.payload;
    },
    setFrequency: (state, action) => {
      state.frequency = action.payload;
    },
    setFrequencyType: (state, action) => {
      state.frequencyType = action.payload;
    },
    resetState: (state, action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    //fulfilled, rejected,pending
    builder
      .addCase(addHabit.fulfilled, (state, action) => {
        state.habits = [...state.habits, action.payload];
        state.habitTitle = "";
        state.frequencyType = "daily";
        state.frequency = 6;
      })
      .addCase(habitDone.fulfilled, (state, action) => {
        let id = action.payload.id;
        state.habits = state.habits.map((habit) => {
          if (habit.id === id) {
            return action.payload;
          }
          return habit;
        });
      })
      .addCase(undoHabit.fulfilled, (state, action) => {
        let id = action.payload.id;
        state.habits = state.habits.map((habit) => {
          if (habit.id === id) {
            return action.payload;
          }
          return habit;
        });
      })
      .addCase(fetchHabits.fulfilled, (state, action) => {
        state.habits = action.payload;
      })
      .addCase(resetStreak.fulfilled, (state, action) => {
        const id = action.payload;
        state.habits = state.habits.map((habit) => {
          if (habit.id === id) {
            return {
              ...habit,
              weekly_count: 0,
              last_completion_date: null,
              streak: 0,
            };
          }
          return habit;
        });
      })

      .addCase(resetWeeklyCount.fulfilled, (state, action) => {
        const id = action.payload;
        state.habits = state.habits.map((habit) => {
          if (habit.id === id) {
            return {
              ...habit,
              last_completion_date: null,
              weekly_count: 0,
            };
          }
          return habit;
        });
      })
      .addCase(deleteHabit.fulfilled, (state, action) => {
        const id = action.payload;
        state.habits = state.habits.filter((habit) => habit.id !== id);
      });
  },
});

//SELECTOR
export const selectHabits = (state) => state.habit.habits;
export const selectHabitTitle = (state) => state.habit.habitTitle;
export const selectFrequency = (state) => state.habit.frequency;
export const selectFrequencyType = (state) => state.habit.frequencyType;

export const {
  setFetchedData,
  setHabitTitle,
  setFrequency,
  setFrequencyType,
  resetState,
} = habitSlice.actions;
export default habitSlice.reducer;
