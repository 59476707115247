import React, { useState, useEffect } from "react";
import "./Analytics.css";
import { request } from "../../util/util";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Analytics = () => {
  const [analytics, setAnalytics] = useState({});
  useEffect(() => {
    const fetchAnalytics = async () => {
      const { status, res } = await request("GET", "profile/analytics");
      setAnalytics({
        ...res,
        category_goal_count: [
          ...res.category_goal_count,
          {
            category: "No Category",
            number_of_goals:
              parseInt(res.total_goals) -
              res.category_goal_count.reduce((accumulator, currentItem) => {
                return (
                  accumulator + (parseInt(currentItem["number_of_goals"]) || 0)
                );
              }, 0),
          },
        ],
      });
    };
    fetchAnalytics();
  }, []);

  const pieChartData = {
    labels: ["In Progress", "Completed", "Pending"],
    datasets: [
      {
        label: "Goals",
        data: [
          analytics.in_progress_goals,
          analytics.completed_goals,
          analytics.pending_goals,
        ],
        backgroundColor: [
          "rgba(46, 255, 137,0.8)",
          "rgba(156, 156, 156, 0.8)",
          "rgba(156, 156, 156, 0.3)",
        ],
        borderWidth: 0,
      },
    ],
  };

  const barOptions = {
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Goals per Category",
      },
    },
  };
  function dynamicColor() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgba(" + r + "," + g + "," + b + ", 0.5)";
  }

  const colors = analytics.category_goal_count
    ? analytics.category_goal_count.map(() => dynamicColor())
    : [];

  const barData = {
    labels: ["Goals"],
    datasets: analytics.category_goal_count
      ? analytics.category_goal_count.map((item, index) => {
          return {
            label: item.category,
            data: [item.number_of_goals],
            backgroundColor: colors[index],
            borderWidth: 1,
            barThickness: 60,
          };
        })
      : [],
  };

  const graphDivClasses =
    "w-full md:h-[350px] flex flex-row justify-center items-center";
  return (
    <div className="flex flex-col gap-10 items-center mb-20">
      <h2 className="text-2xl text-black font-regular">
        Total Goals: {analytics.total_goals}
      </h2>
      <div
        className={
          "flex flex-col md:flex-row gap-16 justify-center w-full items-center "
        }
      >
        <div className={graphDivClasses}>
          <Bar options={barOptions} data={barData} />
        </div>
        <div className={graphDivClasses}>
          <Pie data={pieChartData} />
        </div>
      </div>
    </div>
  );
};

export default Analytics;
