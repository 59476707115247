import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import Modal from "./Modal";
import { request } from "../util/util";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const NotesModal = ({
  viewNotesModal,
  setViewNotesModal,
  notes,
  setNotes,
  goal_id,
}) => {
  const [dataFetched, setDataFetched] = useState(false);
  const [note, setNote] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      // try {
      //   console.log(`${BASE_URL}/goal/notes/${goal_id}`);
      //   const jsonResponse = await fetch(`${BASE_URL}/goal/notes/${goal_id}`, {
      //     credentials: "include",
      //   });
      //   const response = await jsonResponse.json();
      //   setNotes(response); // Assuming the API response contains the tasks data
      //   setDataFetched(true); // Set the flag to indicate that data has been fetched
      // } catch (error) {
      //   console.error(error);
      // }
      const { res, msg, status } = await request(
        "GET",
        `goal/notes/${goal_id}`
      );
      if (status) {
        setNotes(res);
        setDataFetched(true);
      }
    };

    // Fetch data only if viewTasks is true and data has not been fetched yet
    if (viewNotesModal && !dataFetched) {
      fetchData();
    }
  }, [viewNotesModal, dataFetched, goal_id, setNotes]);

  const handleDeleteNote = async (id) => {
    // await fetch(`${BASE_URL}/goal/notes/delete/${id}`, {
    //   method: "DELETE",
    //   credentials: "include",
    // });
    const { res, msg, status } = await request(
      "DELETE",
      `goal/notes/delete/${id}`
    );
    if (status) setNotes(notes.filter((curr) => curr.id !== id));
  };

  const handleAddNote = async () => {
    const currentDate = new Date().toISOString();
    // const response = await fetch(`${BASE_URL}/goal/notes/new`, {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     goal_id: goal_id,
    //     content: note,
    //     submission_time: currentDate,
    //   }),
    // });
    const { res, msg, status } = await request("POST", `goal/notes/new`, {
      goal_id: goal_id,
      content: note,
      submission_time: currentDate,
    });

    if (status) {
      setNotes([
        ...notes,
        { id: res, content: note, submission_time: currentDate },
      ]);
      setNote("");
    }
  };

  // Convert the date to a readable string using toLocaleString
  const formattedDate = (submission_time) => {
    const originalDate = new Date(submission_time);
    return originalDate.toLocaleString();
  };

  return (
    <Modal show={viewNotesModal} setShow={setViewNotesModal}>
      <div className="flex mb-6 items-center">
        <input
          type="text"
          className="flex-grow w-full text-lg border border-gray-500 rounded-sm mr-1 text-md font-regular"
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
          }}
        />
        <button
          className="flex-shrink-0 bg-primary-color rounded-md px-2 py-1 text-md font-regular whitespace-nowrap"
          onClick={handleAddNote}
        >
          Add Note
        </button>
      </div>

      {notes.length > 0 ? (
        notes.map((note, index) => (
          <div
            key={index}
            className="border border-black flex justify-between mb-0.5 py-1 px-2 items-center"
          >
            <div>
              <p className="ext-sm text-gray-600 font-light text-sm">
                {formattedDate(note.submission_time)}
              </p>
              <p className="text-lg ml-2.5 font-regular text-black">
                {note.content}
              </p>
            </div>
            <button
              onClick={() => {
                handleDeleteNote(note.id);
              }}
            >
              <AiFillDelete className="fill-black hover:fill-red-500 size-6" />
            </button>
          </div>
        ))
      ) : (
        <p className="text-center">No Notes</p>
      )}
    </Modal>
  );
};

export default NotesModal;
