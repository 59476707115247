import React, { useState } from "react";
import Modal from "./Modal";
import { IoPersonCircleSharp } from "react-icons/io5";
import GoalPost from "./GoalPost/GoalPost";
import { useSelector } from "react-redux";
import { selectProfileInfo } from "../features/userSlice";

const NewPost = ({
  show,
  setShow,
  currentSection,
  setPosts,
  username,
  navigate,
}) => {
  const [selectedOption, setSelectedOption] = useState("social");
  const [title, setTitle] = useState("");
  const [input, setInput] = useState("");
  const user = useSelector(selectProfileInfo);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handlePost = async (e) => {
    e.preventDefault();
    // console.log("handlepost");
    if (username === "") {
      navigate("/login");
    }
    try {
      // console.log("in try");
      if (title.trim() !== "") {
        // console.log("input.trim!=empty");
        let jsonRes = await fetch(BASE_URL + "/posts/addpost", {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            title,
            body: input,
            // post_date: currentDate,
            type: selectedOption,
          }),
        });

        let res = await jsonRes.json();
        if (currentSection === selectedOption) {
          const currentDate = new Date().toLocaleString();

          setPosts((prevPosts) => {
            return [
              {
                id: res,
                username: username,
                image: user.image,
                likes: 0,
                liked: false,
                body: input,
                title: title,
                post_date: currentDate,
                type: selectedOption,
              },
              ...prevPosts,
            ];
          });
        }
        setTitle("");
        setInput("");
      } else {
        // console.log("input empty");
      }
    } catch (error) {
      // console.log("error post");
      // console.log(error);
    }
  };

  const postBtnDisabled = !title.trim();

  return (
    <Modal show={show} setShow={setShow}>
      <form className="flex flex-col" onSubmit={handlePost}>
        <select
          id="selectOptions"
          value={selectedOption}
          onChange={handleSelectChange}
          className="cursor-pointer rounded-md drop-shadow-md w-4/6 max-w-96 self-center border-[1px] border-black outline-none font-regular p-1 mb-5"
        >
          <option value="social">Friends</option>
          <option value="community">Community</option>
        </select>
        <div className="flex flex-col gap-2 w-full lg:w-5/6 lg:max-w-5xl self-center  bg-white">
          <div className="flex flex-row gap-2 items-center pr-4 ">
            <IoPersonCircleSharp className="fill-gray-200" size={70} />
            <input
              className="w-full border-b-2 border-gray-400 h-[40px] outline-none font-semiBold text-md bg-inherit "
              placeholder="Title (required)"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <textarea
            className=" outline-none border-[1px] border-gray-300  rounded-md w-full h-36 text-base p-2 resize-none overflow-y-auto self-center font-regular text-sm"
            placeholder="Content (optional)"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <div
            className={`self-end ${
              postBtnDisabled ? "bg-gray-200" : "bg-primary-color"
            } px-4 py-1 h-fit font-regular rounded-md`}
          >
            <button type="submit">Post</button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default NewPost;
