import React, { useState } from "react";
import Modal from "./Modal";
import { request } from "../util/util";
import { toast } from "react-toastify";

const FeedbackModal = ({ show, setShow }) => {
  const [selectedOption, setSelectedOption] = useState("social");
  const [name, setName] = useState("");
  const [input, setInput] = useState("");
  const [hideName, setHideName] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("in handle submit");

    if (!submitBtnDisabled) {
      console.log("before request");
      const { status } = await request("POST", "user/review", {
        review: input,
        name: hideName ? null : name,
      });
      if (status) {
        setName("");
        setInput("");
        setShow(false);
        toast.success("Form submitted! Thank you💚");
      } else {
        toast.error("Error submitting form🙁");
      }
    }
  };

  const submitBtnDisabled = (!hideName && !name.trim()) || !input.trim();

  return (
    <Modal show={show} setShow={setShow}>
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-2 w-full lg:w-5/6 lg:max-w-5xl self-center  bg-white">
          <div className="flex flex-col gap-1 mb-6">
            <p className="text-center text-xl font-semiBold">
              Feedback & Reviews
            </p>
            <p className="text-secondary-color text-center text-sm font-regular">
              Your input helps us improve and grow
            </p>
          </div>
          <div className="flex flex-col">
            <p className="text-sm font-semiBold">
              Submissions may be used for testimonials
            </p>
            <div class="flex items-center mb-4">
              <input
                type="checkbox"
                checked={hideName}
                onChange={() => {
                  setHideName(!hideName);
                }}
                class="mr-2"
              />
              <label for="privacyCheckbox" class="text-sm">
                Hide my name
              </label>
            </div>
          </div>
          {!hideName && (
            <input
              className=" text-sm w-full border-b-2 border-gray-400 h-[40px] outline-none font-regular bg-inherit "
              placeholder="First Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          )}
          <textarea
            className=" outline-none border-[1px] border-gray-300  rounded-md w-full h-36 text-base p-2 resize-none overflow-y-auto self-center font-regular text-sm"
            placeholder="Feedback / Review"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />

          <div
            className={`self-end ${
              submitBtnDisabled ? "bg-gray-200" : "bg-primary-color"
            } px-4 py-1 h-fit font-regular rounded-md`}
          >
            <button type="submit">Submit</button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default FeedbackModal;
