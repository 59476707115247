import React from "react";
import { FaInstagram } from "react-icons/fa6";
import { FaReddit } from "react-icons/fa";

const SocialLinks = () => {
  const lineClasses = "w-1/6 h-[2px] bg-black";
  return (
    <div className="flex flex-row justify-center gap-8 items-center w-full max-w-[300px] min-w-[100px]">
      <div className={lineClasses}></div>
      <a href="https://www.instagram.com/friengle_official/">
        <FaInstagram
          size={30}
          className="fill-secondary-color hover:fill-primary-color transition duration-300"
        />
      </a>
      <a href="https://www.reddit.com/r/spark_community/">
        <FaReddit
          size={30}
          className="fill-secondary-color hover:fill-primary-color transition duration-300"
        />
      </a>
      <div className={lineClasses}></div>
    </div>
  );
};

export default SocialLinks;
