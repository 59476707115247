import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import Login from "./pages/login/Login";
import MainHub from "./pages/mainhub/MainHub";
import Register from "./pages/register/Register";
import Journal from "./pages/journaling/Journal";
// import SocialFeed from "./pages/social-feed/SocialFeed";
import GoalTracking from "./pages/goal-tracking/GoalTracking";
import Home from "./pages/home/Home";
import HabitTracking from "./pages/habit-tracking/HabitTracking";
import PrivateRoute from "./components/PrivateRoute";
import NotFound from "./components/NotFound/NotFound";
import Profile from "./pages/profile/Profile";
import About from "./pages/about/About";
import AdminPanel from "./pages/adminpanel/AdminPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  authCheck,
  incrementTime,
  selectLoggedIn,
  selectUsername,
  // selectFcmTokenSaved,
  logOut,
  setLoading,
  // setFcmTokenSaved,
} from "./features/userSlice";
import { getFriendsData } from "./features/friendsSlice";
import Search from "./pages/search/Search";

import Comments from "./pages/comments/Comments";
import Navbar from "./components/Navbar";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AccountabilityCenter from "./pages/accountability-center/AccountabilityCenter";
import Dashboard from "./pages/Dashboard";
import Notifications from "./pages/Notifications";
import { getNotifications } from "./features/notificationsSlice";
import Header from "./components/Header";
import { request } from "./util/util";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Cookies from "js-cookie";

export const router = createBrowserRouter([
  { path: "/", element: <About /> },
  { path: "/register", element: <Register /> },
  { path: "/login", element: <Login /> },
  { path: "/admin-panel", element: <AdminPanel /> },
  { path: "*", element: <NotFound /> },
  {
    element: (
      <div className="flex flex-col w-screen h-screen">
        <Header />
        <div
          className="flex flex-row mt-14 w-full"
          style={{ height: "calc(100vh - 3.5rem)" }}
        >
          <Navbar />
          <div
            className="p-4 lg:p-6 lg:ml-80 w-full lg:w-[calc(100%-20rem)]"
            style={{ height: "calc(100vh - 3.5rem)" }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    ),
    children: [
      {
        path: "/social",
        element: <Home />,
      },
      {
        path: "/profile/:username",
        element: <Profile />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/comments/:postId",
        element: <Comments />,
      },
      {
        path: "/comments/:postId/:postTitle",
        element: <Comments />,
      },
      {
        element: (
          <PrivateRoute>
            <Outlet />
          </PrivateRoute>
        ),
        children: [
          { path: "dashboard", element: <Dashboard /> },
          { path: "notifications", element: <Notifications /> },
          { path: "goals", element: <GoalTracking /> },
          {
            path: "accountability-center",
            element: <AccountabilityCenter />,
          },
        ],
      },
    ],
  },
]);

function App() {
  const dispatch = useDispatch();
  const username = useSelector(selectUsername);
  const loggedIn = useSelector(selectLoggedIn);
  // const fcmTokenSaved = useSelector(selectFcmTokenSaved);

  const setupNotifications = async () => {
    // console.log("setup notifs");
    const setNotifications = Cookies.get("setNotifications");
    console.log('setNotifications', setNotifications);
    if (!setNotifications) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          // console.log("permission granted");
          const messaging = getMessaging(); // Get the Messaging service instance
          getToken(messaging) // Pass your VAPID key if using web push
            .then(async (token) => {
              // console.log(`fcm token ${token}`);
              const { status } = await request("POST", "save-token", {
                token: token,
              });
              if (status) {
                const expirationDate = new Date(2147483647 * 1000); // Maximum date supported by most browsers
                Cookies.set("setNotifications", "true", {
                  expires: expirationDate,
                });
                // console.log("token saved");
                // dispatch(setFcmTokenSaved(true));
              } else {
                // console.log("failed to save token");
              }
            })
            .catch((error) => {
              // console.error("Error getting token:", error);
            });
          // messaging.onMessage((payload) => {
          //   console.log("Received message:", payload);
          //   // Display the notification
          //   const notification = new Notification(payload.notification.title, {
          //     body: payload.notification.body,
          //   });
          // });
        } else {
          // console.log("User has not granted permission");
        }
      });
    }
  };

  useEffect(() => {
    let timer;
    if (loggedIn) {
      dispatch(getFriendsData({ tab: "all", username }));
      dispatch(getNotifications());
      timer = setInterval(() => {
        request("PUT", "user/increment-time");
      }, 60000);
      setupNotifications();
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [loggedIn]);

  useEffect(() => {
    const handleForegroundMessage = () => {
      let messaging = getMessaging();
      onMessage(messaging, (payload) => {
        // console.log("Message received. ", payload);
        // dispatch(addNotification(payload.notification));
        const { title, body } = payload.notification;
        new Notification(title, { body });
      });
    };
    // dispatch(setLoading(true));
    dispatch(authCheck());
    handleForegroundMessage();
  }, [dispatch]);

  return (
    <div className="app">
      {/* <Navbar
        showNav={showNav}
        toggleMenu={toggleMenu}
        loggedIn={loggedIn}
        username={username}
      /> */}
      <RouterProvider router={router} />
      <ToastContainer />
    </div>
  );
}

export default App;
