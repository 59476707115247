import React, { useState, useEffect } from "react";
import "./GoalPost.css";
import { GoGoal } from "react-icons/go";
import { request } from "../../util/util";

// const GoalPost = ({ data, tasks }) => {
const GoalPost = ({ data, display, navigate = null, dispatch = null }) => {
  const {
    goal_id,
    goal_title,
    goal_type,
    is_shared,
    deadline,
    start_date,
    countinggoal_count,
    countinggoal_total,
  } = data;
  const [viewTasks, setViewTasks] = useState(
    display === "complete" ? true : false
  );

  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { res, status } = await request("GET", `goal/tasks/${goal_id}`);
      if (status) {
        setTasks(res);
      }
    };

    if (viewTasks && tasks.length === 0) {
      fetchData();
    }
  }, [viewTasks, tasks.length]);

  function formatDate(dateString) {
    try {

      const date = new Date(dateString);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      return new Intl.DateTimeFormat("en-GB", options).format(date);
    } catch (e) {
      return "Invalid date";
    }
  }

  return (
    <div className="border-2 border-gray-100 shadow-md rounded-lg p-5   w-full  relative flex flex-col items-center gap-2 self-center">
      <GoGoal className="absolute top-0 left-[1%] fill-green-300 size-7 -translate-y-2/4" />
      <p className="text-sm text-secondary-color font-semiBold self-start">
        {is_shared && "Shared"}
      </p>
      <p className="text-xl font-bold text-black w-full md:w-4/6 self-center text-center my-1">
        {goal_title}
      </p>

      <div className="flex flex-row gap-2 text-xs text-black self-center">
        <p className="text-center">
          <span className="font-semiBold">Started: </span>
          <span className="font-regular">{formatDate(start_date)}</span>
        </p>
        {deadline && (
          <p className="text-center">
            <span className="font-semiBold">Deadline: </span>
            <span className="font-regular">{formatDate(deadline)}</span>
          </p>
        )}
      </div>
      {goal_type === "counting" ? (
        <>
          <p className="text-md font-semiBold">
            <span className="text-primary-color">{countinggoal_count}</span>/
            {countinggoal_total}
          </p>
        </>
      ) : (
        goal_type === "milestone" && (
          <div className="self-start w-full">
            <button
              type="button"
              className="text-primary-color text-sm font-bold"
              onClick={() => setViewTasks(!viewTasks)}
            >
              {viewTasks ? "Hide Tasks" : "View Tasks"}
            </button>
            {viewTasks && (
              <div className="animate-slideInDown  mt-3 bg-[rgba(0,0,0,0.01)] rounded-md w-full flex flex-col gap-2">
                {tasks.map((task, index) => {
                  return (
                    <p
                      className={`text-md font-regular pl-3 py-1 mx-1 ${
                        task.done
                          ? "line-through text-gray-400 "
                          : " text-black "
                      } ${
                        index + 1 != tasks.length
                          ? " border-b-[1px] border-gray-200 "
                          : ""
                      }`}
                    >
                      {task.task}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default GoalPost;
