import React, { useEffect, useState, useRef } from "react";
import "./Profile.css";
import { useParams, useNavigate } from "react-router-dom";
import Friends from "../../components/Friends/Friends";
import Post from "../../components/Post/Post";
import Analytics from "../../components/Analytics/Analytics";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUsername,
  editProfile,
  selectProfileInfo,
} from "../../features/userSlice";
import NotFound from "../../components/NotFound/NotFound";
import ProfilePosts from "../../components/ProfilePosts/ProfilePosts";
import { IoPersonCircleSharp } from "react-icons/io5";
import { Helmet } from "react-helmet-async";
import SEO from "../../components/SEO";
import { titleClasses } from "../../util/globals";
import { FaRegEdit } from "react-icons/fa";
import { request } from "../../util/util";
import { OrbitProgress } from "react-loading-indicators";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Profile = () => {
  const dispatch = useDispatch();
  const { username } = useParams();
  const currentUser = useSelector(selectUsername);
  const [chosenCategory, setChosenCategory] = useState("friends");
  const [userExists, setUserExists] = useState(true);
  const [loading, setLoading] = useState(true);
  const [friendshipStatus, setFriendshipStatus] = useState({});
  const [userData, setUserData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const hiddenFileInput = useRef(null);
  const [image, setImage] = useState(null);
  const profileInfo = useSelector(selectProfileInfo);
  const [bio, setBio] = useState(
    currentUser === username ? profileInfo.bio : ""
  );
  const [savingNewInfo, setSavingNewInfo] = useState(false);
  const handleClick = (event) => {
    // Programmatically click the hidden file input element
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage({ image: URL.createObjectURL(file), file: file });
    }
    console.log("File uploaded:", file);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (username !== currentUser) {
          const response = await fetch(`${BASE_URL}/profile/${username}`, {
            method: "GET",
            credentials: "include",
          });

          if (response.ok) {
            const res = await response.json();
            setFriendshipStatus(res.data.friendship_status);
            setUserExists(true);
            setUserData(res.data.user);
            setBio(res.data.user.bio ?? "");
          } else {
            setUserExists(false);
          }
        } else {
          setBio(profileInfo.bio);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    if (currentUser === username) {
      setBio(profileInfo.bio ?? "");
      // setImage(profileInfo.image && { image: profileInfo.image });
    } else {
      setBio("");
    }
  }, [currentUser, username]);

  const handleAddFriend = async () => {
    const response = await fetch(
      `${BASE_URL}/profile/${username}/friend-request`,
      {
        method: "POST",
        credentials: "include",
      }
    );

    if (response.ok) {
      setFriendshipStatus({ ...friendshipStatus, sent_req: true });
    }
  };

  const handleCancelRequest = async () => {
    const response = await fetch(
      `${BASE_URL}/profile/${username}/cancel-request`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    if (response.ok) {
      setFriendshipStatus({ ...friendshipStatus, sent_req: false });
    }
  };

  const handleRemoveFriend = async () => {
    const response = await fetch(
      `${BASE_URL}/profile/${username}/remove-friend`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    if (response.ok) {
      setFriendshipStatus({ ...friendshipStatus, friendship_status: false });
    }
  };
  const handleAcceptRequest = async () => {
    const response = await fetch(
      `${BASE_URL}/profile/${username}/accept-request`,
      {
        method: "POST",
        credentials: "include",
      }
    );

    if (response.ok) {
      setFriendshipStatus({
        ...friendshipStatus,
        friendship_status: true,
        received_req: false,
      });
    }
  };
  const handleDeclineRequest = async () => {
    const response = await fetch(
      `${BASE_URL}/profile/${username}/decline-request`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    if (response.ok) {
      setFriendshipStatus({ ...friendshipStatus, received_req: false });
    }
  };

  function getButtonClasses(buttonType) {
    let baseClasses = "text-md p-2 rounded-md";

    switch (buttonType) {
      case "decline":
      case "cancel":
      case "remove":
        return `${baseClasses} bg-red-500 text-white`;
      case "accept":
      case "sendFriendRequest":
        return `${baseClasses} bg-green-500 text-white`;
      default:
        return baseClasses;
    }
  }

  const handleEditProfile = async () => {
    if (isEditing) {
      console.log(`bio ${bio}`);
      let reqObj = {
        image: image ? image?.file : null,
        bio: bio,
      };
      setSavingNewInfo(true);
      const { res, status } = await request(
        "PUT",
        "user/edit-profile",
        reqObj,
        true
      );
      if (status) {
        //dispatch change profile info
        console.log("res:");
        console.log(res);
        dispatch(
          editProfile({
            bio,
            image: res.image,
          })
        );
      } else {
        //toast error
      }
      setSavingNewInfo(false);
    }
    setIsEditing(!isEditing);
  };

  if (loading) return <p>Loading...</p>;

  if (userExists) {
    let displayedUserData = {
      bio: bio,
      image:
        currentUser === username ? profileInfo["image"] : userData["image"],
    };
    return (
      <div className="w-full flex flex-col gap-8">
        {/* <Helmet>
          <title>{username}'s Profile - Friengle Community Member</title>
          <meta
            name="description"
            content={`Dive into ${username}'s profile on Friengle to discover goals, achievements, and insights. Connect and collaborate for shared success in our goal-oriented community.`}
          />
        </Helmet> */}
        <SEO
          title={`${username}'s Profile`}
          description={`${username} profile on Friengle.`}
        />
        <div className="flex flex-row gap-4">
          <h1 className={titleClasses}>
            {currentUser == username ? "My " : `${username}'s `}Profile
          </h1>
          {savingNewInfo ? (
            <OrbitProgress
              color="#32cd32"
              size="small"
              text=""
              textColor=""
              style={{ fontSize: "6px" }}
            />
          ) : (
            currentUser == username && (
              <button
                className="font-semiBold underline text-primary-color"
                onClick={handleEditProfile}
              >
                {isEditing ? "Save" : "Edit"}
              </button>
            )
          )}
        </div>
        <div className="flex flex-col items-center gap-4">
          <div className="w-fit self-center relative">
            {displayedUserData["image"] || image ? (
              <img
                src={image?.image ?? displayedUserData["image"]}
                className="size-28 rounded-full border-2 border-gray-50 shadow-md"
              />
            ) : (
              <IoPersonCircleSharp className="fill-accent-color size-28" />
            )}
            {isEditing && (
              <>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
                <FaRegEdit
                  className="absolute bottom-3 right-1 size-6 text-primary-color hover:cursor-pointer"
                  onClick={handleClick}
                />
              </>
            )}
            {/* <h1 className="font-semiBold text-xl text-center">{username}</h1> */}
          </div>
          <div className="w-full md:w-5/6">
            <h2 className="text-lg font-semiBold text-center">Bio</h2>
            {currentUser === username && isEditing ? (
              <div>
                <input
                  className={`font-light text-base text-secondary-color w-full border-b-2 p-2 border-gray-200 focus:outline-none`}
                  value={bio}
                  onChange={(e) => {
                    setBio(e.target.value);
                  }}
                  maxLength={300}
                />
                <p className="text-end">{bio.length}/300</p>
              </div>
            ) : (
              <p className="font-light text-base text-center text-secondary-color w-full mt-2">
                {bio && bio.trim() !== ""
                  ? bio
                  : "This user hasn't added a bio yet."}
              </p>
            )}
          </div>
        </div>

        <div className="flex flex-row gap-3">
          {username !== currentUser && (
            <>
              {friendshipStatus.friendship_status ? (
                <button
                  onClick={handleRemoveFriend}
                  className={getButtonClasses("remove")}
                >
                  Unfriend
                </button>
              ) : friendshipStatus.sent_req ? (
                <button
                  onClick={handleCancelRequest}
                  className={getButtonClasses("cancel")}
                >
                  Cancel Request
                </button>
              ) : friendshipStatus.received_req ? (
                <>
                  <button
                    onClick={handleAcceptRequest}
                    className={getButtonClasses("accept")}
                  >
                    Accept Request
                  </button>
                  <button
                    onClick={handleDeclineRequest}
                    className={getButtonClasses("decline")}
                  >
                    Decline
                  </button>
                </>
              ) : (
                <button
                  onClick={() => {
                    if (currentUser) {
                      handleAddFriend();
                    } else {
                      navigate("/login?redirect=/profile/" + username);
                    }
                  }}
                  className={getButtonClasses("sendFriendRequest")}
                >
                  Add friend
                </button>
              )}
            </>
          )}
        </div>

        <div className="border-b-2 border-gray-200 flex flex-row gap-2">
          {/* {["Posts", "Friends", "Analytics"].map((category) => { */}
          {["Friends", ...(currentUser === username ? ["Analytics"] : [])].map(
            (category) => {
              {
                /* {["Friends"].map((category) => { */
              }
              let categoryToLower = category.toLowerCase();
              return (
                <button
                  className={`text-lg p-2 ${
                    chosenCategory === categoryToLower
                      ? "font-semiBold border-b-4 border-solid border-green-400 bg-gray-100"
                      : "font-light border-b-2 border-solid border-gray-300"
                  }`}
                  onClick={() => {
                    setChosenCategory(categoryToLower);
                  }}
                  disabled={chosenCategory === categoryToLower}
                >
                  {category}
                </button>
              );
            }
          )}
        </div>

        {chosenCategory === "friends" ? (
          <Friends
            isCurrentUser={currentUser === username}
            username={username}
          />
        ) : (
          //  : chosenCategory === "posts" ? (
          //   // <ProfilePosts username={username} currentUser={currentUser} />
          //   <div></div>
          // )
          currentUser === username && <Analytics />
        )}
      </div>
    );
  }
  return <NotFound />;
};

export default Profile;
