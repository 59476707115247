import React, { useState, useEffect } from "react";
import "./Home.css";
import Post from "../../components/Post/Post";
import { useSelector } from "react-redux";
import {
  selectUsername,
  selectRole,
  selectLoading,
} from "../../features/userSlice";
import { FaFireAlt } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { selectLoggedIn } from "../../features/userSlice";
import { selectFriendCount } from "../../features/friendsSlice";
import {
  useSearchParams,
  useNavigate,
  useLocation,
  NavLink,
} from "react-router-dom";
import profilePlaceholder from "../../assets/profile.png";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import { request } from "../../util/util";
import { IoPersonCircleSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import NewPost from "../../components/NewPost";
import { Helmet } from "react-helmet-async";
import SEO from "../../components/SEO";
import { titleClasses } from "../../util/globals";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const Home = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let urlSection = searchParams.get("section");
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [members, setMembers] = useState(null);
  const [showPostModal, setShowPostModal] = useState(false);
  const [section, setSection] = useState(
    urlSection === "community" || urlSection === "friends"
      ? urlSection
      : "community"
  );
  const [filter, setFilter] = useState("new");
  const username = useSelector(selectUsername);
  const role = useSelector(selectRole);
  const [hasMore, setHasMore] = useState(true);
  const loggedIn = useSelector(selectLoggedIn);
  const loading = useSelector(selectLoading);
  const navigate = useNavigate();
  const location = useLocation();
  const friendCount = useSelector(selectFriendCount);
  // const friends = [];

  useEffect(() => {
    const fetchMemberCount = async () => {
      const { res } = await request("GET", "community/members-count");
      setMembers(res.members);
    };
    fetchMemberCount();
  }, []);

  useEffect(() => {
    if (
      !urlSection ||
      (urlSection !== "community" && urlSection !== "friends")
    ) {
      navigate("/social?section=community");
    }
    if (!loading) {
      getPosts(section, filter);
    }
  }, [section, filter, loading]);

  const getPosts = async (section, filter) => {
    // console.log("getposts", section, filter, page);
    if (section === "community" || section === "friends") {
      let urn = "";
      if (section === "community") {
        urn += "community";
        if (filter === "new") {
          urn += `/new`;
        } else if (filter === "top") {
          urn += "/top";
        } else if (filter === "trending") {
          urn += "/trending";
        }
      } else {
        if (!loggedIn) {
          //check eza loading abl, if !loading and !logged in show toast
          navigate("/login");
          toast.info("Please login to continue.");
          return;
        }
        urn += "social-feed";
      }
      urn += `?page=${page}`;
      const { res, status } = await request("GET", urn);
      if (status) {
        setPosts([...posts, ...res]);
        setPage(page + 1);
        if (res.length < 10) {
          setHasMore(false);
        }
      }
    }
    // console.log("has more", hasMore);
  };

  const handleDelete = (postId) => {
    fetch(`${BASE_URL}/posts/deletepost/${postId}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => {
        setPosts(posts.filter((post) => post.id !== postId));
      })
      .catch((error) => {
        // console.error("Error deleting post:", error.message);
      });
  };

  const handleFilterChange = (currentFilter) => {
    if (currentFilter !== filter) {
      setFilter(currentFilter);
      setPosts([]);
      setPage(1);
    }
  };

  const handleSectionChange = (newSection) => {
    if (newSection !== section) {
      navigate(`/social?section=${newSection}`);
      setHasMore(true);
      setPosts([]);
      setPage(1);
      setSection(newSection);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      {/* <Helmet>
        <title>
          Friengle: Connect & Achieve Goals Together in Our Social Community
        </title>
        <meta
          name="description"
          content="Join Friengle, the leading social platform for goal tracking. Dive into a community where motivation meets action. Share your journey, learn from others, and achieve your goals together. Discover the power of community-driven goal achievement today."
        />
      </Helmet> */}
      <SEO
        title={"Social Feed - Friengle"}
        description={
          "Dive into a community where motivation meets action. Share your journey, learn from others, and achieve your goals together."
        }
      />
      <div className="flex flex-row justify-between items-center">
        <h1 className={titleClasses}>Community and Friends Feed</h1>

        <button
          onClick={() => {
            if (loggedIn) {
              setShowPostModal(true);
            } else {
              navigate("/login");
            }
          }}
          className="md:px-3 md: py-2 rounded-md bg-gray-200 h-fit w-28 font-semiBold text-md"
        >
          {loggedIn ? "New Post" : "Login"}
        </button>
      </div>

      {/* <h2 className="">
        {section === "community" ? "Explore the community" : "Your social feed"}
      </h2> */}
      <div className="flex flex-col gap-3 w-full md:w-4/6">
        <p className="text-secondary-color text-base font-light italic duration-300">
          {section === "community" ? (
            <span key="community" className="animate-flash-once">
              Explore our <span className="underline">Community</span> Feed,
              where anyone can post and interact!
            </span>
          ) : (
            <span key="friends" className="animate-flash-once">
              Explore your dedicated <span className="underline">Friends</span>{" "}
              Feed, where you and your friends can exclusively post and
              interact.
            </span>
          )}
        </p>
        <div>
          <p className="font-regular text-sm">
            {section === "community" ? "Community Members" : "Friends"}
          </p>
          <p className="text-secondary-color text-base font-bold">
            {section === "community" ? members : friendCount}
          </p>
        </div>
      </div>
      <div className="sticky top-14 bg-white z-10 flex flex-col gap-4">
        <div className="flex flex-row gap-2 border-b-2 border-gray-200 ">
          {["Community", loggedIn && "Friends"]
            .filter(Boolean)
            .map((item, index) => {
              const itemToLower = item.toLowerCase();
              return (
                <button
                  key={index}
                  className={` text-md  border-solid p-2 ${
                    section === itemToLower
                      ? "font-semiBold border-b-4 bg-gray-100 border-green-400"
                      : "font-light border-b-2 border-gray-300"
                  }`}
                  onClick={() => {
                    handleSectionChange(itemToLower);
                  }}
                  disabled={section === itemToLower}
                >
                  {item}
                </button>
              );
            })}
        </div>
        {section === "community" && (
          <div className="flex flex-row gap-3 sticky ">
            {["Top", "Trending", "New"].map((item, index) => {
              const itemToLower = item.toLowerCase();
              return (
                <button
                  key={index}
                  onClick={() => {
                    handleFilterChange(itemToLower);
                  }}
                  className={`py-1.5 px-3 rounded-md ${
                    filter === itemToLower
                      ? "font-bold bg-gray-300"
                      : "font-light bg-gray-100"
                  }`}
                >
                  {item}
                </button>
              );
            })}
          </div>
        )}
      </div>
      {/* <div className="flex flex-col gap-2 w-full lg:w-5/6 lg:max-w-5xl self-center  bg-white">
        <div className="flex flex-row gap-2 items-center pr-4 ">
          <IoPersonCircleSharp className="fill-gray-200" size={70} />
          <input
            className="w-full border-b-2 border-gray-400 h-[40px] outline-none font-semiBold text-md bg-inherit "
            placeholder="Title (required)"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <textarea
          className=" outline-none  rounded-sm w-full h-36 text-base p-2 resize-none overflow-y-auto self-center font-regular text-sm shadow-md"
          placeholder="Content (optional)"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <div
          className={`self-end ${
            postBtnDisabled ? "bg-gray-200" : "bg-primary-color"
          } px-4 py-1 h-fit font-regular rounded-md`}
        >
          <button
            onClick={() => {
              handlePost();
            }}
          >
            Post
          </button>
        </div>
      </div> */}
      <InfiniteScroll
        dataLength={posts.length}
        next={() => getPosts(section, filter)}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        className="w-full  flex flex-col gap-10 mt-4 mb-10 m-auto lg:px-4"
      >
        {posts &&
          posts.map((post) => (
            <Post
              key={post.id}
              post={post}
              section={section}
              handleDelete={handleDelete}
              commentsButtonDisabled={false}
              display={"preview"}
            />
          ))}
      </InfiniteScroll>
      <NewPost
        show={showPostModal}
        setShow={setShowPostModal}
        setPosts={setPosts}
        username={username}
        navigate={navigate}
        currentSection={section}
      />
    </div>
  );
};

export default Home;
