import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../util/util";

const initialState = {
  friends: [],
  received: [],
  pending: [],
  friendCount: null,
};

export const getFriendsData = createAsyncThunk(
  "friends/getFriendsData",
  async ({ tab, username, navigate = null }, { rejectWithValue, dispatch }) => {
    let url = `profile`;
    // console.log(`tab:${tab},username:${username}`);
    switch (tab) {
      case "all":
        url += `/${username}/friends/all`;
        break;
      case "pending":
        url += `/friends/pending`;
        break;
      case "received":
        url += `/friends/received`;
        break;
      default:
        break;
    }
    if (url) {
      const { res, status } = await request("GET", url);
      if (status) {
        return { tab: tab, arr: res.arr };
      } else {
        return rejectWithValue("!response.ok");
      }
    } else {
      return rejectWithValue("!url");
    }
  }
);

export const friendsSlice = createSlice({
  name: "friends",
  initialState,
  reducers: {
    resetState: (state, action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFriendsData.fulfilled, (state, action) => {
        const { tab, arr } = action.payload;
        if (tab === "all") {
          state.friends = arr;
          state.friendCount = arr.length;
        } else if (tab === "received") {
          state.received = arr;
        } else {
          state.pending = arr;
        }
      })
      .addCase(getFriendsData.rejected, (state, action) => {
        // console.log(action.payload);
      });
  },
});

export const selectAllFriends = (state) => state.friends.friends;
export const selectPendingRequests = (state) => state.friends.pending;
export const selectReceivedRequests = (state) => state.friends.received;
export const selectFriendCount = (state) => state.friends.friendCount;

export const { resetState } = friendsSlice.actions;

export default friendsSlice.reducer;
