import React, { useState } from "react";
import GoalPost from "./GoalPost/GoalPost";
import { FaChevronRight } from "react-icons/fa6";
import AccountabilityMessages from "./AccountabilityMessages";
import { request } from "../util/util";
import { useSelector } from "react-redux";
import { selectUsername } from "../features/userSlice";

const AccountabilityGoal = ({ goal }) => {
  const [message, setMessage] = useState("");
  const [latestMessage, setLatestMessage] = useState({
    latest_message: goal.latest_message,
    latest_message_username: goal.latest_message_username,
  });
  const [viewAccountabilityMessages, setViewAccountabilityMessages] =
    useState(false);
  const [messages, setMessages] = useState([]);
  const username = useSelector(selectUsername);

  const handleAddMessage = async () => {
    let trimmedMessage = message.trim();
    if (!trimmedMessage) return;
    const { status, res } = await request(
      "POST",
      "goal/accountability-message/new",
      {
        goal_id: goal.id,
        message: trimmedMessage,
        goal_title: goal.title,
        accountability_partners: [
          ...goal.goal_participants,
          ...goal.accountability_partners,
        ]
          .filter((participant) => participant.username !== username)
          .map((partner) => partner.id),
      }
    );
    if (status) {
      setMessages([
        {
          username,
          message_text: trimmedMessage,
          timestamp: res,
        },
        ...messages,
      ]);
      setLatestMessage({
        latest_message: trimmedMessage,
        latest_message_username: username,
      });
      setMessage("");
    }
  };

  // console.log("accountability partners");
  // console.log(goal.accountability_partners);
  // console.log("goal participants");
  // console.log(goal.goal_participants);
  return (
    <div className="flex flex-col items-center w-full">
      <p className="text-sm font-semiBold text-secondary-color">
        Chat Members:{" "}
        {[...goal.accountability_partners, ...goal.goal_participants]
          .map((partner) => partner.username)
          .join(", ")}
      </p>
      <GoalPost
        key={goal.id}
        data={{
          goal_id: goal.id,
          goal_title: `${goal.goal_participants
            .map((partner) => partner.username)
            .join(", ")}: ${goal.title}`,
          goal_type: goal.type,
          is_shared: goal.is_shared,
          deadline: goal.deadline,
          start_date: goal.start_date,
          countinggoal_count: goal.count,
          countinggoal_total: goal.total,
        }}
        display={"preview"}
      />
      {latestMessage.latest_message && (
        <div className="flex flex-row w-full justify-center items-center gap-10 my-2">
          <div className="flex flex-col basis-5/6">
            <p className="font-semiBold text-lg text-secondary-color">
              {latestMessage.latest_message_username}
            </p>
            <p className="font-regular text-sm pl-2 pt-1">
              {latestMessage.latest_message}
            </p>
          </div>
          <button
            className="flex flex-row justify-center items-center  p-1 "
            onClick={() => {
              setViewAccountabilityMessages(true);
            }}
          >
            <p className="text-center text-secondary-color text-sm font-semiBold">
              View All Messages
            </p>
            <FaChevronRight className="fill-secondary-color text-xl" />
          </button>
        </div>
      )}
      <div className="flex my-3 items-center">
        <input
          type="text"
          className="flex-grow w-full text-lg border border-gray-500 rounded-sm mr-1 text-md font-regular"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />
        <button
          className="flex-shrink-0 bg-primary-color rounded-md px-2 py-1 text-md font-regular whitespace-nowrap"
          onClick={handleAddMessage}
        >
          Add Message
        </button>
      </div>
      {!latestMessage && (
        <p className="block font-light text-xs text-secondary-color mt-2">
          No Previous Messages
        </p>
      )}
      <AccountabilityMessages
        goal_id={goal.id}
        viewAccountabilityMessages={viewAccountabilityMessages}
        setViewAccountabilityMessages={setViewAccountabilityMessages}
        messages={messages}
        setMessages={setMessages}
      />
    </div>
  );
};

export default AccountabilityGoal;
