import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import Modal from "./Modal";
import { request } from "../util/util";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const SharedGoalParticipantsModal = ({
  viewParticipantsModal,
  setViewParticipantsModal,
  goal_id,
}) => {
  const [dataFetched, setDataFetched] = useState(false);
  const [participants, setParticipants] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      // try {
      //   console.log(`${BASE_URL}/goal/participants/${goal_id}`);
      //   const jsonResponse = await fetch(
      //     `${BASE_URL}/goal/participants/${goal_id}`,
      //     {
      //       credentials: "include",
      //     }
      //   );
      //   const response = await jsonResponse.json();
      //   setParticipants(response); // Assuming the API response contains the tasks data
      //   setDataFetched(true); // Set the flag to indicate that data has been fetched
      // } catch (error) {
      //   console.error(error);
      // }
      const { res, msg, status } = await request(
        "GET",
        `goal/participants/${goal_id}`
      );
      if (status) {
        setParticipants(res);
        setDataFetched(true);
      }
    };

    // Fetch data only if viewTasks is true and data has not been fetched yet
    if (viewParticipantsModal && !dataFetched) {
      fetchData();
    }
  }, [viewParticipantsModal, dataFetched, goal_id]);

  //   const handleDeleteNote = async (id) => {
  //     await fetch(`${BASE_URL}/goal/notes/delete/${id}`, {
  //       method: "DELETE",
  //       credentials: "include",
  //     });
  //     setNotes(notes.filter((curr) => curr.id !== id));
  //   };

  //   const handleAddNote = async () => {
  //     const currentDate = new Date().toISOString();
  //     const response = await fetch(`${BASE_URL}/goal/notes/new`, {
  //       method: "POST",
  //       credentials: "include",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         goal_id: goal_id,
  //         content: note,
  //         submission_time: currentDate,
  //       }),
  //     });

  //     setNotes([
  //       ...notes,
  //       { id: response, content: note, submission_time: currentDate },
  //     ]);
  //     setNote("");
  //   };

  // Convert the date to a readable string using toLocaleString
  //   const formattedDate = (submission_time) => {
  //     const originalDate = new Date(submission_time);
  //     return originalDate.toLocaleString();
  //   };

  return (
    <Modal show={viewParticipantsModal} setShow={setViewParticipantsModal}>
      {participants.length > 0 ? (
        participants.map((participant, index) => (
          <div
            key={index}
            className="border border-black flex justify-between mb-0.5 px-3"
          >
            <p className="text-md ml-2.5 font-light p-1">
              {participant.username}
            </p>
          </div>
        ))
      ) : (
        <p className="text-center">No Notes</p>
      )}
    </Modal>
  );
};

export default SharedGoalParticipantsModal;
