import React, { useState } from "react";
import Modal from "./Modal";
import { request } from "../util/util";
// const BASE_URL = process.env.REACT_APP_BASE_URL;
const ReportModal = ({
  viewReportModal,
  setViewReportModal,
  post_id,
  setReported,
}) => {
  const [reason, setReason] = useState("");
  const handleReport = async () => {
    // try {
    //   fetch(BASE_URL + "/posts/reportPost", {
    //     method: "POST",
    //     credentials: "include",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       id: post_id,
    //       reason: reason,
    //     }),
    //   }).then(() => {
    //     setReported(true);
    //   });
    // } catch (error) {
    //   console.log(error);
    // }
    const { res, msg, status } = await request("POST", `posts/reportPost`, {
      id: post_id,
      reason: reason,
    });
    if (status) {
      setReported(true);
    }
  };

  return (
    <Modal show={viewReportModal} setShow={setViewReportModal}>
      <div className="flex flex-col items-center gap-2 text-left">
        <textarea
          type="text"
          id="captionInput"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Include reason(optional)"
          className="w-4/5 h-24 p-0"
        />
        <button
          onClick={handleReport}
          className="w-1/5 py-1.25 bg-red-500 text-white border-0"
        >
          Report
        </button>
      </div>
    </Modal>
  );
};

export default ReportModal;
