import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { Analytics } from "@vercel/analytics/react";
// import { SpeedInsights } from "@vercel/speed-insights/react";
import { inject } from "@vercel/analytics"; // Add this line
import { HelmetProvider } from "react-helmet-async";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
inject(); // And this one
const container = document.getElementById("root");
const root = createRoot(container);
initializeApp({
  apiKey: "AIzaSyAFdw5hSQdFbJkuGmTAb4ZEtdXqUFQvfjU",
  authDomain: "friengle-bb7e6.firebaseapp.com",
  projectId: "friengle-bb7e6",
  storageBucket: "friengle-bb7e6.appspot.com",
  messagingSenderId: "666493981250",
  appId: "1:666493981250:web:27c59cea09c392e7f753db",
  measurementId: "G-XKBDLBSHVK",
});
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service worker registered:", registration);
    })
    .catch((error) => {
      console.error("Error registering service worker:", error);
    });
}

root.render(
  // <React.StrictMode>
  <HelmetProvider>
    <Provider store={store}>
      <Analytics />
      {/* <SpeedInsights /> */}
      <App />
    </Provider>
  </HelmetProvider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
