import React, { useState, useEffect } from "react";
import Modal from "../Modal";
import "./AddGoalModal.css";
import { request } from "../../util/util";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAllFriends } from "../../features/friendsSlice";
import { addGoal, selectCategories } from "../../features/goalSlice";

const AddGoalModal = ({ view, setView }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [targetValue, setTargetValue] = useState(0);
  const [inputValues, setInputValues] = useState([""]);
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [selectedDeadline, setSelectedDeadline] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const friends = useSelector(selectAllFriends);
  const types = ["basic", "milestone", "counting"];
  const categories = useSelector(selectCategories);

  const resetForm = () => {
    setTitle("");
    setTargetValue(0);
    setInputValues([""]);
    setSelectedFriends([]);
    setSelectedDeadline("");
    setIsChecked(false);
    setSelectedType("");
    setSelectedCategory("");
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your logic to handle form submission here
    let goal = {};
    let url;
    const currentDate = new Date().toISOString();
    goal.start_date = currentDate;
    goal.title = title;
    goal.deadline = selectedDeadline;
    goal.is_shared = isChecked;
    goal.goal_category_id = selectedCategory;
    if (isChecked) {
      if (selectedFriends.length > 0) {
        goal.selectedFriends = selectedFriends;
      }
    }

    // goal.goal_type_id = types.find((obj) => obj.type === selectedType).id;
    goal.type = selectedType;
    if (selectedType === "milestone") {
      // goal.tasksArr = inputValues.slice(0, -1).map((value) => {
      //   return { title: value };
      // });
      if (inputValues[inputValues.length - 1].trim() === "") {
        goal.tasksArr = inputValues
          .slice(0, -1)
          .map((value) => ({ title: value }));
      } else {
        goal.tasksArr = inputValues.map((value) => ({ title: value }));
      }
      url = `new/milestone`;
    } else if (selectedType === "counting") {
      goal.total = targetValue;

      url = `new/counting`;
    } else {
      url = `new/basic`;
    }

    resetForm();
    await dispatch(addGoal({ url, goal, navigate }));
  };

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;

    // Add an empty string for the next input field if the last input is filled
    if (index === inputValues.length - 1 && value !== "" && index < 4) {
      newInputValues.push("");
    }

    setInputValues(newInputValues);
  };

  const handleDateChange = (e) => {
    setSelectedDeadline(e.target.value);
  };

  // const handleCheckboxChange = (friendUsername) => {
  //   const updatedSelectedFriends = [...selectedFriends];

  //   if (updatedSelectedFriends.includes(friendUsername)) {
  //     // Remove friend if already selected
  //     updatedSelectedFriends.splice(
  //       updatedSelectedFriends.indexOf(friendUsername),
  //       1
  //     );
  //   } else {
  //     updatedSelectedFriends.push(friendUsername);
  //   }

  //   setSelectedFriends(updatedSelectedFriends);
  // };

  const handleCheckboxChange = (friend) => {
    const updatedSelectedFriends = [...selectedFriends];
    let index = updatedSelectedFriends.findIndex(
      (current) => current.id === friend.id
    );

    if (index !== -1) {
      updatedSelectedFriends.splice(index, 1);
    } else {
      updatedSelectedFriends.push(friend);
    }

    setSelectedFriends(updatedSelectedFriends);
  };

  const isButtonDisabled =
    !title ||
    !selectedType ||
    (selectedType === "counting" && !targetValue) ||
    (isChecked && selectedFriends.length === 0) ||
    selectedType === "border border-gray-300 p-2 rounded-md";

  const labelClasses = "font-semiBold text-md text-black mt-2";
  const selectClasses =
    "border border-gray-300 p-2 rounded-md font-regular text-md";
  return (
    <Modal show={view} setShow={setView}>
      <form onSubmit={handleSubmit} className=" flex flex-col gap-4 p-2 h-max">
        <div className="self-center w-full md:w-4/6 md:max-w-96 flex flex-col items-stretch">
          <p className={labelClasses}>Select Category:</p>
          <select
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
            }}
            className={selectClasses}
          >
            <option value="">All</option>
            {categories.map((category, index) => (
              <option key={index} value={category.id}>
                {category.category}
              </option>
            ))}
          </select>

          <p className={labelClasses}>Select Type:</p>
          <select
            value={selectedType}
            onChange={handleTypeChange}
            className={selectClasses}
          >
            <option value="" disabled>
              Select Type
            </option>
            {types.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>

          <p className={labelClasses}>Deadline(optional):</p>
          <input
            type="date"
            value={selectedDeadline}
            min={new Date().toISOString().split("T")[0]}
            onChange={handleDateChange}
            className={selectClasses}
          />
        </div>
        <div className="w-full">
          <p className={labelClasses}>Title:</p>
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            className="border border-gray-300 p-2 rounded-md w-full font-regular"
          />
        </div>

        {selectedType === "milestone" ? (
          <div>
            <p className={labelClasses}>Add tasks:</p>
            <div className="flex flex-row flex-wrap gap-2">
              {inputValues.map((inputValue, index) => (
                <input
                  key={index}
                  type="text"
                  value={inputValue}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  className="border border-gray-300 p-2 rounded-md my-1 font-regular"
                />
              ))}
            </div>
          </div>
        ) : (
          selectedType === "counting" && (
            <div className="self-center md:self-start">
              <p className={labelClasses}>Target Value:</p>
              <input
                type="number"
                min={0}
                value={targetValue}
                onChange={(e) => setTargetValue(e.target.value)}
                className="border border-gray-300 p-2 rounded-md w-full md:max-w-44 font-regular"
              />
            </div>
          )
        )}

        <div className=" flex items-center gap-2">
          <p className={labelClasses}>Shared:</p>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => {
              setIsChecked(!isChecked);
            }}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
        </div>

        {isChecked && (
          <div>
            <p className={labelClasses}>Select Friends</p>
            <div className=" flex flex-col gap-2">
              {friends.map((friend, i) => (
                <div key={i} className=" flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={selectedFriends.some(
                      (partner) => partner.id === friend.id
                    )}
                    onChange={() => handleCheckboxChange(friend)}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <p className="font-regular text-sm">{friend.username}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        <button
          type="submit"
          className={`w-full md:w-96 self-center ${
            isButtonDisabled ? "bg-gray-200" : "bg-primary-color"
          } px-4 py-1 h-fit font-regular rounded-md`}
          disabled={isButtonDisabled}
        >
          Save
        </button>
      </form>
    </Modal>
  );
};

export default AddGoalModal;
