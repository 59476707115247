import React from "react";
import { GrClose } from "react-icons/gr";

const Modal = ({ show, setShow, children }) => {
  return (
    show && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
        <div className="bg-white p-5 rounded-lg shadow-lg w-[95vw] max-w-4xl max-h-[90vh] overflow-auto">
          <button
            className="text-2xl bg-transparent border-none"
            onClick={() => {
              setShow(false);
            }}
          >
            <GrClose color="red" />
          </button>
          {children}
        </div>
      </div>
    )
  );
};

export default Modal;
