import React from "react";
import "./AuthHeader.css";
import { useNavigate } from "react-router-dom";

const AuthHeader = ({ page }) => {
  const navigate = useNavigate();
  const loginPage = page === "login";
  const buttonClasses =
    "text-sm font-semiBold py-1.5 px-3.5 rounded-full border-2 border-transparent";
  return (
    <div className="flex flex-row justify-between items-end py-2 px-4 border-b-2 shadow-sm border-gray-100 z-10">
      <h4 className="font-semiBold">
        Friengle
        <span className="text-[14px] text-primary-color underline decoration-primary-color absolute top-3 font-bold">
          beta
        </span>
      </h4>
      <div className="flex flex-row gap-2">
        <button
          onClick={() => {
            navigate(page === "register" ? "/login" : "/register");
          }}
          className={`text-white bg-primary-color ${buttonClasses} hover:bg-white hover:text-primary-color duration-300 hover:border-primary-color`}
        >
          {page === "register" ? "Login" : "Sign Up"}
        </button>
        <button
          onClick={() => {
            navigate(page === "about" ? "/login" : "/");
          }}
          className={`text-black bg-gray-300 ${buttonClasses} hover:bg-white  duration-300 hover:border-gray-500`}
        >
          {page === "about" ? "Login" : "About"}
        </button>
      </div>
    </div>
  );
};

export default AuthHeader;
