import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../util/util";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState = {
  chosenEntry: null,
  searchTerm: "",
  categoryInput: "",
  chosenCategory: null,
  showCategoryInput: false,
  fetchedData: false,
  categories: [],
  entries: [],
  currentEntry: { title: "", content: "", category: "" },
  isLoadingEntries: false,
  errorLoadingEntries: false,
  page: 1,
  hasMore: true,
  isEditing: false,
};

export const fetchJournalCategories = createAsyncThunk(
  "journal/fetchCategories",
  async () => {
    // console.log(`${BASE_URL}/journal/categories`);
    // const jsonResponse = await fetch(`${BASE_URL}/journal/categories`, {
    //   credentials: "include",
    // });
    // const response = await jsonResponse.json();
    const { res, msg, status } = await request("GET", `journal/categories`);
    if (status) {
      return res;
    }
  }
);
export const editJournalEntry = createAsyncThunk(
  "journal/editJournal",
  async (journal) => {
    // console.log(`${BASE_URL}/journal/edit`);
    // console.log("received data:");
    // console.log(`id:${journal.id}`);
    // await fetch(`${BASE_URL}/journal/edit`, {
    //   credentials: "include",
    //   method: "PUT",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     id: journal.id,
    //     category: journal.category,
    //     content: journal.content,
    //     title: journal.title,
    //   }),
    // });
    // console.log(journal);
    const { res, msg, status } = await request("PUT", `journal/edit`, {
      id: journal.id,
      category: journal.category,
      content: journal.content,
      title: journal.title,
    });
    if (status) {
      return journal;
    }
    // const response = await jsonResponse.json();
    // return response;
  }
);
export const fetchJournalEntries = createAsyncThunk(
  "journal/fetchEntries",
  async ({ page, category }) => {
    // console.log(category);
    // console.log(
    //   `${BASE_URL}/journal/entries?category=${category}&page=${page}`
    // );
    // const jsonResponse = await fetch(
    //   `${BASE_URL}/journal/entries?category=${category}&page=${page}`,
    //   {
    //     credentials: "include",
    //   }
    // );
    // const response = await jsonResponse.json();
    // return response;
    const { res, msg, status } = await request(
      "GET",
      `journal/entries?category=${category}&page=${page}`
    );
    if (status) {
      return res;
    }
  }
);

export const addJournalEntry = createAsyncThunk(
  "journal/addEntry",
  async (entry) => {
    const currentDate = new Date().toISOString();
    // console.log(`journal content:${entry.content}`);
    let body = {
      category_title: entry.category,
      title: entry.title,
      journal_content: entry.content,
      date_saved: currentDate,
    };
    // await fetch(`${BASE_URL}/journal/addentry`, {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(body),
    // });
    // console.log("after post req journal/addEntry");
    const { res, msg, status } = await request(
      "POST",
      `journal/addentry`,
      body
    );
    if (status) {
      return {
        id: res.id,
        category: body.category_title,
        title: body.title,
        content: body.journal_content,
        date_saved: body.date_saved,
      };
    }
  }
);
export const addCategory = createAsyncThunk(
  "journal/addCategory",
  async (category) => {
    // await fetch(`${BASE_URL}/journal/addcategory`, {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     category_title: category,
    //   }),
    // });
    const { res, msg, status } = await request("POST", `journal/addcategory`, {
      category_title: category,
    });
    if (status) {
      return category;
    }
    // console.log("after post req journal/addCategory");
  }
);
export const deleteJournal = createAsyncThunk(
  "journal/deleteJournal",
  async (id) => {
    // await fetch(`${BASE_URL}/journal/delete/${id}`, {
    //   method: "DELETE",
    //   credentials: "include",
    // });
    // console.log("after delete req journal/delete");
    const { res, msg, status } = await request(
      "DELETE",
      `journal/delete/${id}`
    );
    if (status) {
      return id;
    }
  }
);

export const journalSlice = createSlice({
  name: "journal",
  initialState,
  reducers: {
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    },
    setCurrentEntry: (state, action) => {
      // console.log("setCurrentEntry called with payload:", action.payload);
      // const { title, content, category, id } = action.payload;
      // state.currentEntry = { id, title, content, category };
      state.currentEntry = action.payload;
    },
    clearCurrentEntry: (state) => {
      state.currentEntry = { title: "", content: "", category: "" };
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    clearSearchTerm: (state) => {
      state.searchTerm = "";
    },
    setChosenEntry: (state, action) => {
      state.chosenEntry = action.payload;
    },
    clearChosenEntry: (state) => {
      state.chosenEntry = null;
      // state.page = 1;
      // state.hasMore = true;
      // state.entries = [];
    },
    setChosenCategory: (state, action) => {
      state.chosenCategory = action.payload;
      state.page = 1;
      state.hasMore = true;
      state.entries = [];
    },
    clearChosenCategory: (state, action) => {
      state.chosenCategory = null;
    },
    toggleViewCategoryInput: (state) => {
      state.showCategoryInput = !state.showCategoryInput;
    },
    setCategoryInput: (state, action) => {
      state.categoryInput = action.payload;
    },
    clearCategoryInput: (state, action) => {
      state.categoryInput = "";
    },
    setFetchedData: (state, action) => {
      state.fetchedData = action.payload;
    },
    incrementPage: (state, action) => {
      state.page += 1;
    },
    resetPage: (state) => {
      state.page = 1;
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    resetState: (state, action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJournalEntries.pending, (state) => {
        state.isLoadingEntries = true;
        state.errorLoadingEntries = false;
      })
      .addCase(fetchJournalEntries.rejected, (state) => {
        state.isLoadingEntries = false;
        state.errorLoadingEntries = true;
      })
      .addCase(fetchJournalEntries.fulfilled, (state, action) => {
        state.entries = [...state.entries, ...action.payload];
        state.isLoadingEntries = false;
        state.errorLoadingEntries = false;
        state.page += 1;
        if (action.payload.length < 10) {
          state.hasMore = false;
        }
      })
      .addCase(fetchJournalCategories.pending, (state) => {})
      .addCase(fetchJournalCategories.rejected, (state) => {})
      .addCase(fetchJournalCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(addJournalEntry.fulfilled, (state, action) => {
        state.entries = [...state.entries, action.payload];
        state.currentEntry = { title: "", content: "", category: "" };
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.categories = [...state.categories, { title: action.payload }];
        state.categoryInput = "";
      })
      .addCase(editJournalEntry.fulfilled, (state, action) => {
        const journal = action.payload;
        const index = state.entries.findIndex(
          (entry) => entry.id === journal.id
        );
        if (index !== -1) {
          state.entries[index] = journal;
          state.chosenEntry = journal;
          state.currentEntry = { title: "", content: "", category: null };
        }
        state.isEditing = false;
      })
      .addCase(deleteJournal.fulfilled, (state, action) => {
        // console.log("id received");
        // console.log(action.payload);
        state.entries = state.entries.filter(
          (entry) => entry.id !== action.payload
        );
        state.chosenEntry = null;
      });
  },
});

export const selectCategoryInput = (state) => state.journal.categoryInput;
export const selectFetchedData = (state) => state.journal.fetchedData;
export const selectShowCategoryInput = (state) =>
  state.journal.showCategoryInput;
export const selectEntries = (state) => state.journal.entries;
export const selectCategories = (state) => state.journal.categories;
export const selectCurrentEntry = (state) => state.journal.currentEntry;
export const selectSearchTerm = (state) => state.journal.searchTerm;
export const selectChosenEntry = (state) => state.journal.chosenEntry;
export const selectChosenCategory = (state) => state.journal.chosenCategory;
export const isLoadingEntries = (state) => state.journal.isLoadingEntries;
export const errorLoadingEntries = (state) => state.journal.errorLoadingEntries;
export const selectPage = (state) => state.journal.page;
export const selectHasMore = (state) => state.journal.hasMore;
export const selectIsEditing = (state) => state.journal.isEditing;

export const {
  setCurrentEntry,
  clearCurrentEntry,
  setSearchTerm,
  setChosenEntry,
  clearChosenEntry,
  setChosenCategory,
  clearChosenCategory,
  toggleViewCategoryInput,
  setCategoryInput,
  clearCategoryInput,
  setFetchedData,
  incrementPage,
  resetPage,
  setHasMore,
  setIsEditing,
  resetState,
} = journalSlice.actions;

export default journalSlice.reducer;
