import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLoading, selectLoggedIn } from "../features/userSlice";
import { toast } from "react-toastify";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const currentUrl = location.pathname;
  const loading = useSelector(selectLoading);
  const loggedIn = useSelector(selectLoggedIn);

  useEffect(() => {
    if (!loading && !loggedIn) {
      // console.log("private route")
      toast.info("Please login to continue.");
    }
  }, [loading, loggedIn]);

  if (!loading && !loggedIn) {
    let url = "/login";
    if (currentUrl !== "/") {
      url += `?redirect=${encodeURIComponent(currentUrl)}`;
    }
    return <Navigate to={url} />;
  }

  return children;
};

export default PrivateRoute;
