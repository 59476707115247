import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import Modal from "./Modal";
import { request } from "../util/util";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const AccountabilityMessages = ({
  viewAccountabilityMessages,
  setViewAccountabilityMessages,
  messages,setMessages,
  goal_id,
}) => {
  useEffect(() => {
    const fetchData = async () => {
      const { res, msg, status } = await request(
        "GET",
        `goal/accountability-messages/${goal_id}`
      );
      if (status) {
        setMessages(res);
        // console.log(res);
      }
    };
    fetchData();
  }, []);

  const formattedDate = (submission_time) => {
    const originalDate = new Date(submission_time);
    return originalDate.toLocaleString();
  };

  return (
    <Modal
      show={viewAccountabilityMessages}
      setShow={setViewAccountabilityMessages}
    >
      {messages.length > 0 ? (
        messages.map((message, index) => (
          <div
            key={index}
            className="border border-black flex justify-between mb-0.5 py-1 px-2 items-center"
          >
            <div>
              <p className="text-sm text-gray-600 font-light text-sm">
                {formattedDate(message.timestamp)}
              </p>
              <p className="text-md ml-2.5 font-regular text-black">
                <span className="font-semiBold text-lg">{message.username}</span>: {message.message_text}
              </p>
            </div>
            {/* <button
              onClick={() => {
                // handleDeleteNote(note.id);
              }}
            >
              <AiFillDelete className="fill-black hover:fill-red-500 size-6" />
            </button> */}
          </div>
        ))
      ) : (
        <p className="text-center">No Messages</p>
      )}
    </Modal>
  );
};

export default AccountabilityMessages;
