import React, { useState, useEffect } from "react";
import { request } from "../../util/util";
import AccountabilityGoal from "../../components/AccountabilityGoal";
import { titleClasses } from "../../util/globals";
import SEO from "../../components/SEO";

const AccountabilityCenter = () => {
  const [goals, setGoals] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  useEffect(() => {
    const getGoals = async () => {
      const { status, res } = await request(
        "GET",
        "goal/accountability-center"
      );
      if (status) {
        // console.log("res");
        // console.log(res.goals);
        setGoals(res.goals);
      } else {
        // console.log("error in fetch");
      }
    };

    getGoals();
  }, []);

  return (
    <div className="flex flex-col w-full pb-10">
      <SEO
        title={`Accountability Center - Friengle`}
        description={`Keep others accountable by reviewing their reports and engaging with them through questions.`}
      />
      <div className="flex flex-col justify-between items-start">
        <h1 className={titleClasses}>Accountability Center</h1>
        <div className="w-full md:w-5/6 lg:w-4/6 2xl:w-1/2  mt-4 mb-16">
          <p className="text-md font-regular text-secondary-color">
            Keep others accountable by reviewing their reports and engaging with
            them through questions.
          </p>

          <p className={`text-sm font-regular mt-4`}>
            To add an accountability partner to a goal, press the 'Share' button
            on your desired goal, select 'Accountability Partners', choose the
            friends you want to keep you accountable, and hit 'Share' to
            confirm.
          </p>
        </div>
        {/* <div className="flex flex-row gap-2 border-b-2 border-gray-200 w-full mt-8 lg:mt-6 mb-10" /> */}
      </div>

      <div className="self-center flex flex-col gap-16 w-full md:w-5/6 max-w-[900px] px-6 md:px-0">
        {goals &&
          goals.map((goal) => {
            return <AccountabilityGoal goal={goal} />;
          })}
      </div>
    </div>
  );
};

export default AccountabilityCenter;
