import React, { useState } from "react";
import Modal from "./Modal";
import { request } from "../util/util";
import { IoPersonCircleSharp } from "react-icons/io5";
import GoalPost from "./GoalPost/GoalPost";
import { useSelector } from "react-redux";
import { selectAllFriends } from "../features/friendsSlice";
import { toast } from "react-toastify";
// const BASE_URL = process.env.REACT_APP_BASE_URL;
const ShareModal = ({
  viewShareModal,
  setViewShareModal,
  type,
  data,
  setAccountabilityPartners,
}) => {
  const [selectedOption, setSelectedOption] = useState("social");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const friends = useSelector(selectAllFriends);
  const [selectedFriends, setSelectedFriends] = useState([]);

  const handleInputChange = (e) => {
    setBody(e.target.value);
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const share = async (e) => {
    e.preventDefault();
    // const currentDate = new Date().toISOString();
    // fetch(`${BASE_URL}/posts/share`, {
    //   method: "POST",
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     caption: caption,
    //     post_date: currentDate,
    //     type: selectedOption,
    //     attachment_type: type,
    //     attachment_id: data.id,
    //   }),
    // }).then(() => {
    //   setSelectedOption("social-feed");
    //   setCaption("");
    //   setViewShareModal(false);
    // });

    let reqBody = {
      attachment_id: data.goal_id,
    };
    let url;
    if (selectedOption === "accountability partner(s)") {
      reqBody["selected_friends"] = selectedFriends;
      reqBody["goal_title"] = data.goal_title;
      url = `goal/accountability-partners`;
    } else {
      reqBody = {
        ...reqBody,
        title: title,
        body: body,
        attachment_type: type,
        type: selectedOption,
      };
      url = `posts/share`;
    }
    const { res, msg, status } = await request("POST", url, reqBody);
    if (status) {
      if (selectedOption === "accountability partner(s)") {
        setAccountabilityPartners((prev) => [...prev, ...selectedFriends]);
        toast.success(
          `Added accountability partner(s) to "${data.goal_title}"`
        );
      }
      setSelectedOption("social");
      setTitle("");
      setBody("");
      setViewShareModal(false);
      setSelectedFriends([]);
    }
  };

  const postBtnDisabled =
    selectedOption === "accountability partner(s)"
      ? selectedFriends.length === 0
      : !title.trim();

  const handleCheckboxChange = (friend) => {
    const updatedSelectedFriends = [...selectedFriends];
    let index = updatedSelectedFriends.findIndex(
      (friendId) => friendId === friend.id
    );

    if (index !== -1) {
      updatedSelectedFriends.splice(index, 1);
    } else {
      updatedSelectedFriends.push(friend.id);
    }

    setSelectedFriends(updatedSelectedFriends);
  };

  return (
    <Modal show={viewShareModal} setShow={setViewShareModal}>
      <form
        className="flex flex-col items-center gap-2.5 text-left"
        onSubmit={share}
      >
        <GoalPost data={data} display={"complete"} />

        <select
          id="selectOptions"
          value={selectedOption}
          onChange={handleSelectChange}
          className="cursor-pointer rounded-md drop-shadow-md w-4/6 max-w-96 self-center border-[1px] border-black outline-none font-regular p-1 my-5"
        >
          <option value="social">Friends</option>
          <option value="community">Community</option>
          <option value="accountability partner(s)">
            Accountability Partner(s)
          </option>
        </select>

        <div className="flex flex-col gap-2 w-full lg:w-5/6 lg:max-w-5xl self-center  bg-white">
          {selectedOption === "accountability partner(s)" ? (
            <div>
              <p className={"text-lg font-regular"}>Select Friends</p>
              <div className=" flex flex-col gap-2">
                {friends.map((friend, i) => {
                  // console.log("friends");
                  // console.log(friends);
                  // console.log("acc partners");
                  // console.log(data.accountability_partners);
                  let isAccountabilityPartner = [
                    ...data.goal_participants,
                    ...data.accountability_partners,
                  ].some((partner) => partner.id === friend.id);
                  return (
                    <div key={i} className=" flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={
                          isAccountabilityPartner ||
                          selectedFriends.some(
                            (partner) => partner === friend.id
                          )
                        }
                        disabled={isAccountabilityPartner}
                        onChange={() => handleCheckboxChange(friend)}
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <p className="font-regular text-sm">{friend.username}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <>
              <div className="flex flex-row gap-2 items-center pr-4 ">
                <IoPersonCircleSharp className="fill-gray-200" size={70} />
                <input
                  className="w-full border-b-2 border-gray-400 h-[40px] outline-none font-semiBold text-md bg-inherit "
                  placeholder="Title (required)"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <textarea
                className=" outline-none border-[1px] border-gray-300  rounded-md w-full h-36 text-base p-2 resize-none overflow-y-auto self-center font-regular text-sm"
                placeholder="Content (optional)"
                value={body}
                onChange={(e) => setBody(e.target.value)}
              />
            </>
          )}

          <div
            className={`self-end ${
              postBtnDisabled ? "bg-gray-200" : "bg-primary-color"
            } px-4 py-1 h-fit font-regular rounded-md`}
          >
            <button type="submit" disabled={postBtnDisabled}>
              Share
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ShareModal;
