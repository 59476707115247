import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Post from "../../components/Post/Post";
import { AiFillDelete } from "react-icons/ai";
import { FaCaretDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectUsername, selectRole } from "../../features/userSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Comments.css";
import { Helmet } from "react-helmet-async";
import { genTitle } from "../../util/util";
import SEO from "../../components/SEO";
import Comment from "../../components/Comment";
import { IoArrowBack } from "react-icons/io5";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const Comments = () => {
  const navigate = useNavigate();
  const { postId, postTitle } = useParams();
  const [input, setInput] = useState("");
  const [comments, setComments] = useState([]);
  const [post, setPost] = useState({});
  const username = useSelector(selectUsername);
  const role = useSelector(selectRole);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(false);
  // const page = "community";
  // const postState = { id: 80 };
  // const username = "test";

  //useEffect get post id from url and fetch post data with /comments/:postId (post data & comments)
  //goal/journal/habit should have a share button, onclick modal with optional caption, select between community and social feed, and submit post button

  //Later:
  //comments should include replies(like yt or insta -> one parent and rest are replies)

  useEffect(() => {
    const fetchPostDetails = async () => {
      setLoading(true);
      let postRes = await fetch(`${BASE_URL}/posts/${postId}`, {
        credentials: "include",
      });
      if (postRes.ok) {
        let post = await postRes.json();
        // console.log(jsonRes);
        setPost(post.post);
        // if (!postTitle) {
        //   window.history.pushState(
        //     {},
        //     "",
        //     `/${post.post.caption}`
        //   );
        // }
        if (!postTitle) {
          navigate(`/comments/${postId}/${genTitle(post.post.title)}`, {
            replace: true,
          });
        }
        setLoading(false);
        // console.log(res.post);
        getComments();
      } else {
        setError(true);
      }
    };

    fetchPostDetails();
  }, [postId]);

  const getComments = async () => {
    let res = await fetch(`${BASE_URL}/posts/comments/${postId}?page=${page}`, {
      credentials: "include",
    });
    if (res.ok) {
      let data = await res.json();
      setComments([...comments, ...data.comments]);
      if (data.comments.length < 10) {
        setHasMore(false);
      }
      setPage(page + 1);
      // console.log(data.comments);
    } else {
      setError(true);
    }
  };

  const handleComment = (e) => {
    e.preventDefault();
    if (!username) {
      alert("Log in first!");
      return;
    }
    if (input.trim() !== "") {
      fetch(`${BASE_URL}/posts/comment/${postId}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          comment: input,
          author_id: post.author_id,
          post_title: post.title,
        }),
      }).then(async (jsonRes) => {
        let res = await jsonRes.json();
        setComments((prevComments) => [
          {
            id: res,
            username: username,
            comment: input,
          },
          ...prevComments,
        ]);
        setInput("");
      });
    }
  };

  const handleCommentDelete = async (comment_id) => {
    fetch(`${BASE_URL}/posts/deletecomment/${comment_id}`, {
      method: "DELETE",
      credentials: "include",
    }).then(() => {
      setComments(comments.filter((comment) => comment.id !== comment_id));
    });
  };

  const handleDelete = async () => {};

  if (loading) {
    return <p>Loading</p>;
  }

  if (error) {
    return <p>Error</p>;
  }

  function createMetaDescription(post) {
    if (post.body.length > 0) {
      let cutoff = post.body.indexOf(".", 150);
      if (cutoff === -1 || cutoff > 160) {
        cutoff = 157;
      }
      return post.body.substring(0, cutoff + 1);
    } else {
      return post.title;
    }
  }

  return (
    <div className="flex flex-col gap-6">
      {/* <Helmet>
        <title>{post.title} - Friengle</title>
        <meta name="description" content={summarizedDescription} />
      </Helmet> */}
      <SEO title={`${post.title}`} description={createMetaDescription(post)} />
      {/* <Post
        commentsButtonDisabled={true}
        handleDelete={handleDelete}
        username={username}
        post={{
          id: 82,
          caption: "habit post",
          post_date: "2023-12-31T19:52:16.785Z",
          likes: "1",
          username: "test",
          liked: true,
          trend_score: "1",
          journal_data: null,
          goal_data: null,
          milestonegoal_tasks: [null],
          habit_data: null,
        }}
        display={"complete"}
      /> */}
      <button
        className="bg-gray-200 hover:bg-gray-300 duration-300 rounded-full p-2 w-fit"
        onClick={() => {
          if (location.key === "default") {
            // No previous history within the app, navigate to a default route
            navigate("/social?section=community");
          } else {
            navigate(-1);
          }
        }}
      >
        <IoArrowBack className="text-black" />
      </button>
      <Post
        commentsButtonDisabled={true}
        handleDelete={handleDelete}
        post={post}
        display={"complete"}
      />

      <div className=" px-0 sm:px-1 md:px-2 mt-5 ">
        <form onSubmit={handleComment} className="flex flex-row w-full ">
          <input
            type="text"
            placeholder="Write a comment..."
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            className="w-5/6 border-[1px] border-gray-500 rounded-tl-lg rounded-bl-lg font-regular p-1 outline-none"
          />
          <button className="bg-primary-color rounded-tr-lg rounded-br-lg font-regular p-2 w-1/6 min-w-fit">
            Comment
          </button>
        </form>
        <InfiniteScroll
          dataLength={comments.length}
          next={() => getComments()}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          className="flex flex-col gap-6 mt-7"
        >
          {comments.map((comment) => (
            <Comment
              comment={comment}
              username={username}
              role={role}
              handleCommentDelete={handleCommentDelete}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Comments;
